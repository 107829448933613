import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setPage } from './slices';
import { createAdmin, getAdminList } from './services';
import {
  getAdminPage,
  getAdminPageSize,
  getAdminsLoading,
  getAdminUsers,
  getAdminUsersLoadMore,
} from './selectors';
import AdminsWrapper from './AdminsWrapper';
import CreateAdminModal from './CreateAdminModal';
import { IAdminCreateRequest } from './types';

const Admins = memo(() => {
  const admins = useAppSelector(getAdminUsers);
  const loadMore = useAppSelector(getAdminUsersLoadMore);
  const isLoading = useAppSelector(getAdminsLoading);
  const page = useAppSelector(getAdminPage);
  const pageSize = useAppSelector(getAdminPageSize);

  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const getList = useCallback(
    async () => dispatch(getAdminList({ page, pageSize })),
    [page, pageSize],
  );

  useEffect(() => {
    getList().then();
  }, [page, pageSize]);

  const onNextClickHandler = useCallback(() => {
    dispatch(setPage(page + 1));
  }, [dispatch, page]);

  const handleOnCreateAdmin = useCallback(async (data: IAdminCreateRequest) => {
    const response = await dispatch(createAdmin(data));
    if (response.meta.requestStatus === 'fulfilled') {
      await getList();
      setOpen(false);
    }
  }, []);

  const handleClose = () => setOpen(false);

  return (
    <>
      <AdminsWrapper
        admins={admins}
        isLoading={isLoading}
        loadMore={loadMore}
        onNextClick={onNextClickHandler}
        onCreateClick={() => {
          setOpen(true);
        }}
      />
      <CreateAdminModal
        handleClose={handleClose}
        open={open}
        onSubmit={handleOnCreateAdmin}
      />
    </>
  );
});

export default Admins;
