import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { createAdmin, getAdminList, removeAdmin } from '../services';
import { IAdminListResponse } from '../types';
import { IUserResponse } from '../../../types';
import { ToastMessage } from '../../../utils/constants';

export interface IUsersState {
  isLoading: boolean;
  admins: IUserResponse[];
  page: number;
  pageSize: number;
  count: number | null;
  loadMore: boolean;
}

const initialState: IUsersState = {
  isLoading: false,
  admins: [],
  page: 1,
  pageSize: 10,
  count: null,
  loadMore: false,
};

export const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAdminList.fulfilled,
      (state, { payload }: PayloadAction<IAdminListResponse>) => {
        const { count, list } = payload;
        state.isLoading = false;
        state.count = count;
        state.admins = state.page > 1 ? [...state.admins, ...list] : list;
        state.loadMore = state.admins.length !== count;
      },
    );
    builder.addCase(getAdminList.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(createAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAdmin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createAdmin.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(removeAdmin.fulfilled, (state, { payload }) => {
      state.admins = state.admins.filter((admin) => admin.id !== payload.id);
    });
    builder.addCase(removeAdmin.rejected, () => {
      toast.warning(ToastMessage.DELETE_ADMIN_ERROR);
    });
  },
});

export const { setPage, setPageSize } = adminsSlice.actions;
export default adminsSlice.reducer;
