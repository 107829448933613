import { ResponseType, AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { IErrorResponse } from '../../types';
import { getErrorMessage } from '../../utils/helpers';
import axiosApiInstance from './interceptors';

interface IGetOptions {
  path: string;
  params?: { [key: string]: any };
  responseType?: ResponseType;
}

interface IAxiosResponse {
  [key: string]: any;
}

export default async function get<T = any>(options: IGetOptions): Promise<T> {
  const { path, params = {}, responseType = 'json' } = options;
  try {
    const res: IAxiosResponse = await axiosApiInstance.get<IAxiosResponse>(
      path,
      {
        responseType,
        params,
      },
    );
    if (res.data.error) {
      throw res.data.error;
    }
    return res.data;
  } catch (error: any) {
    if (error?.response?.data) {
      const { response } = error as AxiosError<IErrorResponse>;

      if (response?.data.statusCode !== 401) {
        toast(getErrorMessage(response?.data));
      }
    }
    throw error?.response?.data;
  }
}
