import React, { memo, useEffect } from 'react';
import AchievementsPage from './components/AchievementsPage';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getAchievementsSelector,
  getAchievementsLoadingSelector,
  getAchievementsLoadMore,
  getAchievementsPageSelector,
} from './selector';
import { deleteAchievement, getAllAchievements } from './service';
import { pageSize } from '../../utils/constants';
import { setPage } from './slices';

const Achievements = memo(() => {
  const dispatch = useAppDispatch();
  const achievements = useAppSelector(getAchievementsSelector);
  const loadMoreStatus = useAppSelector(getAchievementsLoadMore);
  const isLoading = useAppSelector(getAchievementsLoadingSelector);
  const page = useAppSelector(getAchievementsPageSelector);

  useEffect(() => {
    dispatch(getAllAchievements({ page, pageSize }));
  }, [page]);

  useEffect(
    () => () => {
      dispatch(setPage(1));
    },
    [],
  );

  const removeAchievement = (id: string) => {
    dispatch(deleteAchievement({ id }));
  };

  const updatePage = () => {
    dispatch(setPage(page + 1));
  };

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <AchievementsPage
      achievements={achievements}
      deleteAchievement={removeAchievement}
      loadMoreStatus={loadMoreStatus}
      updatePage={updatePage}
    />
  );
});

export default Achievements;
