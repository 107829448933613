import React, { memo } from 'react';
import { Button } from '@mui/material';
import UsersTable from '../UsersTable';
import { IUserResponse } from '../../../types';

interface IUserWrapperProps {
  isLoading: boolean;
  users: IUserResponse[];
  onClickHandler: (item: IUserResponse) => void;
  loadMore: boolean;
  onNextClick: () => void;
}

const UsersWrapper = memo<IUserWrapperProps>(
  ({ isLoading, users, onClickHandler, loadMore, onNextClick }) => (
    <>
      <h1>Users:</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <UsersTable users={users} onClick={onClickHandler} />
          <Button
            disabled={!loadMore}
            color="primary"
            variant="text"
            onClick={onNextClick}
            sx={{ mt: 3 }}
          >
            Load More
          </Button>
        </>
      )}
    </>
  ),
);

export default UsersWrapper;
