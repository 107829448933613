export const API_ROUTES = {
  LOGIN: '/auth/sign-in',
  LOGOUT: '/auth/sign-out',
  ME: '/auth/me',
  COURSES: '/courses',
  LESSONS: '/lessons',
  REFRESH_TOKEN: '/auth/refresh-token',
  USERS: '/users',
  ADMINS: '/users/admins',
  RESET_USER_PASSWORD: '/users/reset-password',
  TRAINERS: '/trainers',
  CHALLENGES: '/challenges',
  ACHIEVEMENTS: '/achievements',
  EXERCISE_OF_THE_DAY: '/exercise-of-the-day',
  GET_S3_VIDEO_UPLOAD_URL: '/s3/get-video-upload-pre-assigned-url',
};
