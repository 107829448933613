import { capitalize, getMonthName } from '../../../utils/helpers';

export const levelsToStr = (level: string[]) => {
  const string = '';
  return level.reduce((acc, currValue, index) => {
    if (index === 0) {
      return `${capitalize(currValue)}`;
    }
    return `${acc}, ${capitalize(currValue)}`;
  }, string);
};

export const formatDate = (date: Date) => `${getMonthName(date).slice(0, 3)} ${date.getDate()}`;
export const getDaysCount = (start: Date, end: Date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((start.valueOf() - end.valueOf()) / oneDay));
};
export const dateToStr = (start: Date, end: Date) => {
  const formattedMonths = `${formatDate(start)} - ${formatDate(end)}`;
  const count = getDaysCount(start, end);
  const countStr = +count === 1 ? `${count} day` : `${count} days`;
  return `${formattedMonths} (${countStr})`;
};
