import { Typography, CardActions, CardContent, Button } from '@mui/material';
import Lottie from 'lottie-react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IAchievement } from '../../types';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { GrayText, StyledCard } from '../../styles';
import AlertDialod from '../../../../components/AlertDialog/alert-dialog';
import { fetchJson } from '../../utils';

interface IAchievementCardProps {
  achievement: IAchievement;
  deleteAchievement: (id: string) => void;
}

const AchievementCard = memo(
  ({ achievement, deleteAchievement }: IAchievementCardProps) => {
    const [json, setJson] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const { name, image, id, description } = achievement;

    const getJsonData = useCallback(async () => {
      if (image && image.url) {
        const response = await fetchJson(image.url);
        setJson(response);
      }
    }, [image]);

    useEffect(() => {
      getJsonData();
    }, [getJsonData]);

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    const handleSubmit = () => {
      deleteAchievement(id);
      handleClose();
    };

    return (
      <>
        <StyledCard>
          {json ? <Lottie animationData={json} loop /> : null}
          <CardContent>
            <Typography variant="h5" component="div" mb={3}>
              {name}
            </Typography>
            <GrayText variant="body1">{description}</GrayText>
          </CardContent>
          <CardActions>
            <Link to={`${APP_ROUTES.ACHIEVEMENTS}/${achievement.id}`}>
              <Button size="medium">Edit</Button>
            </Link>
            <Button size="medium" color="error" onClick={handleOpen}>
              Delete
            </Button>
          </CardActions>
        </StyledCard>
        {isOpen && (
          <AlertDialod
            question="Delete achievement?"
            description="Are you sure you want to delete this achievement?"
            buttonAgree="Delete"
            buttonDisagree="Cancel"
            openStatus={isOpen}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
          />
        )}
      </>
    );
  },
);

export default AchievementCard;
