import { CardActionArea, Card, Typography } from '@mui/material';
import React, { FC, memo, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { ICourseItem } from '../../types';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import AlertDialod from '../../../../components/AlertDialog/alert-dialog';
import { useAppDispatch } from '../../../../hooks';
import { deleteCourse } from '../../service';

interface CourseCardProps {
  course: ICourseItem;
}

const CourseCard: FC<CourseCardProps> = memo(({ course }) => {
  const dispatch = useAppDispatch();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [id, setId] = useState('');

  const changeStatus = (courseId: string) => {
    setId(courseId);
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const handleSubmit = async () => {
    if (id) {
      await dispatch(deleteCourse({ id }));
      setOpenDeleteDialog(false);
    }
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          maxWidth: 600,
        }}
      >
        <Link to={`${APP_ROUTES.COURSES}/${course.id}`}>
          {course.image?.url && (
            <CardActionArea>
              <CardMedia
                component="img"
                height="240"
                image={course.image.url}
                alt={course.name}
              />
            </CardActionArea>
          )}
        </Link>
        <CardContent style={{ height: '250px', wordWrap: 'break-word' }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            {course.name.length > 35
              ? `${course.name.slice(0, 35)}...`
              : course.name}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            {course.description.length > 180
              ? `${course.description.slice(0, 180)}...`
              : course.description}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            Lesson count:
            {' '}
            {course.lessons.length ? course.lessons.length : '0'}
          </Typography>
        </CardContent>
        <CardActions>
          <Link to={`${APP_ROUTES.COURSES}/${course.id}`}>
            <Button size="medium">Edit</Button>
          </Link>
          <Button
            size="medium"
            color="error"
            onClick={() => changeStatus(course.id)}
          >
            Delete
          </Button>
        </CardActions>
      </Card>
      {openDeleteDialog && (
        <AlertDialod
          question="Delete course?"
          description="Are you sure you want to delete the course?"
          buttonAgree="Delete"
          buttonDisagree="Cancel"
          openStatus={openDeleteDialog}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      )}
    </>
  );
});

export default CourseCard;
