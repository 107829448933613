import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import CreateChallenge from './components/CreateChallenge';
import UpdateChallenge from './components/UpdateChallenge';

interface IProps {
  details?: boolean;
}

const ChallengesPage = memo(({ details }: IProps) => {
  const { id } = useParams();

  if (id) {
    return <UpdateChallenge details={details} />;
  }
  return <CreateChallenge />;
});

export default ChallengesPage;
