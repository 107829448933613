export interface IUpdateCourse extends ICourseForm {
  levelRef?: string[];
  previewRef?: string;
  previewImage?: FileList;
  previewVideo?: FileList;
  image?: FileList;
}

export interface ICourseForRequest {
  name?: string;
  description?: string;
  shortDescription?: string;
  level?: ILevel[];
  category?: string;
  levelRef?: string[];
  previewRef?: string;
  isDraft?: boolean;
  isNew?: boolean;
  isPopular?: boolean;
  position?: number;
  previewOption?: IPreviewOption;
  previewImage?: File;
  previewVideo?: File;
  image?: File;
}

export interface ISelectOptionItem<L = string, V = string> {
  label: L;
  value: V;
}

export interface ICategory
  extends ISelectOptionItem<CourseCategory, CourseCategory> {}

export interface ILevel extends ISelectOptionItem<CourseLevel, CourseLevel> {}

export interface IPreviewOption
  extends ISelectOptionItem<PreviewType, PreviewType> {
  disabled?: boolean;
}

export interface ICourseOption extends ISelectOptionItem {
  disabled?: boolean;
}

export interface IUpdateCoursePreviewResponse {
  url: string;
}

export enum MediaFuncEnum {
  preview = 'preview',
  previewVideo = 'previewVideo',
  image = 'image',
  video = 'video',
}

export enum CourseLevel {
  ALL = 'all',
  BEGINNER = 'beginner',
  ADVANCE = 'advance',
}

export enum CourseCategory {
  CARDIO = 'cardio',
  STRETCHING = 'stretching',
}

export enum PreviewType {
  VIDEO = 'video',
  IMAGE = 'image',
}

export interface ICourseForm {
  name?: string;
  description?: string;
  shortDescription?: string;
  level?: ILevel[];
  category?: ICategory;
  isDraft?: boolean;
  isNew?: boolean;
  isPopular?: boolean;
  position?: number;
  previewOption?: IPreviewOption;
  trainer?: string;
}

export interface ICourseCreateForm {
  name?: string;
  description?: string;
  shortDescription?: string;
  level?: ISelectOption[];
  isDraft?: boolean;
  position: number;
  previewOption?: ISelectOption;
  image?: FileList;
  preview?: FileList;
  previewVideo?: FileList;
}

export interface ISelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export const maxPosition = 100;
export const maxName = 60;
export const maxDescription = 170;
