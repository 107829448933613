import { Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import AlertDialod from '../../../../components/AlertDialog/alert-dialog';
import { GrayText, LableBox, StyledCard, LableContainer } from '../../styles';
import { shortenString } from '../../../../utils/helpers';
import { dateToStr, levelsToStr } from '../../utils';
import { IChallenge } from '../../../ChallengesPage/types';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

interface ICardProps {
  challenge: IChallenge;
  changeStatus?: (id: string, isDraft: boolean) => void;
  deleteChallenge?: (id: string) => void;
}

const ChallengeCard = memo(
  ({ challenge, changeStatus, deleteChallenge }: ICardProps) => {
    const [isOpenPublish, setIsOpenPublish] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const {
      name,
      description,
      image,
      isDraft,
      level,
      startDate,
      finishDate,
      id,
    } = challenge;

    const handleClosePublish = () => setIsOpenPublish(false);
    const handleOpenPublish = () => setIsOpenPublish(true);
    const handleCloseDelete = () => setIsOpenDelete(false);
    const handleOpenDelete = () => setIsOpenDelete(true);

    const handleSubmitPublish = () => {
      if (changeStatus) {
        changeStatus(id, true);
      }
      handleClosePublish();
    };
    const handleSubmitDelete = () => {
      if (deleteChallenge) {
        deleteChallenge(id);
      }
      handleCloseDelete();
    };

    return (
      <>
        <StyledCard>
          {image && image?.url && (
            <CardMedia
              component="img"
              height="240"
              image={image.url}
              alt={name}
            />
          )}
          <CardContent>
            <Typography variant="h5" component="div" mb={2}>
              {name}
            </Typography>
            <GrayText variant="body2" mb={2}>
              {shortenString(description)}
            </GrayText>
            <LableContainer>
              <LableBox>
                <GrayText variant="body2">Date Range:</GrayText>
                <Typography variant="body2">
                  {dateToStr(new Date(startDate), new Date(finishDate))}
                </Typography>
              </LableBox>
              <LableBox>
                <GrayText variant="body2">Level:</GrayText>
                <Typography variant="body2">{levelsToStr(level)}</Typography>
              </LableBox>
            </LableContainer>
          </CardContent>
          <CardActions>
            {isDraft ? (
              <>
                <Link to={`${APP_ROUTES.CHALLENGES}/${challenge.id}`}>
                  <Button size="medium">Edit</Button>
                </Link>
                <Button size="medium" color="error" onClick={handleOpenDelete}>
                  Delete
                </Button>
              </>
            ) : (
              <Button size="medium" color="primary" onClick={handleOpenPublish}>
                Unpublish
              </Button>
            )}
          </CardActions>
        </StyledCard>
        {isOpenPublish && (
          <AlertDialod
            question="Unpublish this challenge?"
            description='This challenge will be removed from publication and moved to the "Drafts" section'
            buttonAgree="Unpublish"
            buttonDisagree="Cancel"
            openStatus={isOpenPublish}
            handleSubmit={handleSubmitPublish}
            handleClose={handleClosePublish}
          />
        )}
        {isOpenDelete && (
          <AlertDialod
            question="Delete this challenge?"
            description="Are you sure you want to delete this challenge?"
            buttonAgree="Delete"
            buttonDisagree="Cancel"
            openStatus={isOpenDelete}
            handleSubmit={handleSubmitDelete}
            handleClose={handleCloseDelete}
          />
        )}
      </>
    );
  },
);

export default ChallengeCard;
