import React, { FC, memo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface DialogProps {
  question: string;
  description?: string;
  buttonAgree: string;
  buttonDisagree: string;
  handleSubmit: () => void;
  handleClose: () => void;
  openStatus: boolean;
}

const AlertDialod: FC<DialogProps> = memo(
  ({
    question,
    description,
    buttonAgree,
    buttonDisagree,
    openStatus,
    handleClose,
    handleSubmit,
  }) => (
    <div>
      <Dialog
        open={openStatus}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{question}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{buttonDisagree}</Button>
          <Button color="error" onClick={handleSubmit} autoFocus>
            {buttonAgree}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ),
);

export default AlertDialod;
