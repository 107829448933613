import React, { memo, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchUser, resetUserPassword } from './services';
import { getUser, getUserLoading } from './selectors';
import { APP_ROUTES } from '../../core/router/appRoutes';
import UserPageWrapper from './UserPageWrapper';

const UserPage = memo(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const user = useAppSelector(getUser);
  const isLoading = useAppSelector(getUserLoading);

  useEffect(() => {
    if (id) {
      dispatch(fetchUser({ id }));
    }
  }, [id]);

  const onBackToListClickHandler = useCallback(() => {
    navigate(APP_ROUTES.USERS);
  }, [navigate]);

  const onResetPasswordClick = useCallback(() => {
    if (user) {
      dispatch(resetUserPassword({ userId: user.id }));
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <UserPageWrapper
      user={user}
      isLoading={isLoading}
      onBackClick={onBackToListClickHandler}
      onResetPasswordClick={onResetPasswordClick}
    />
  );
});

export default UserPage;
