import { createAsyncThunk } from '@reduxjs/toolkit';
import { del, get, post } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { ILoginRequest, ILoginResponse } from '../types';
import {
  ICurrentUserResponse,
  IErrorResponse,
  IRefreshTokenRequest,
  ITokenResponse,
} from '../../../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export const login = createAsyncThunk<
ILoginResponse,
ILoginRequest,
ICommonChunkConfig
>('auth/login', async (_data, { rejectWithValue }) => {
  try {
    const response = await post({ path: API_ROUTES.LOGIN, data: _data });
    return response as ILoginResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const logout = createAsyncThunk<any, void, ICommonChunkConfig>(
  'auth/logout',
  async (_data, { rejectWithValue }) => {
    try {
      await del({ path: API_ROUTES.LOGOUT, params: {} });
      return true;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const getAccessToken = createAsyncThunk<
ITokenResponse,
IRefreshTokenRequest,
ICommonChunkConfig
>('auth/refreshAccessToken', async (_data, { rejectWithValue }) => {
  try {
    const response = await post({
      path: API_ROUTES.REFRESH_TOKEN,
      data: _data,
    });
    return response as ITokenResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const getCurrentUser = createAsyncThunk<
ICurrentUserResponse,
void,
ICommonChunkConfig
>('auth/fetchCurrentUser', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({ path: API_ROUTES.ME, params: {} });
    return response as ICurrentUserResponse;
  } catch (error) {
    const errorResponse = error as IErrorResponse;
    return rejectWithValue(errorResponse);
  }
});
