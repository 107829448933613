import React, { FC, memo } from 'react';
import PageWrapper from '../Wrapper';
import LessonCreatePage from './Page/create';
import LessonUpdatePage from './Page/update';

const LessonPage: FC = memo(() => (
  <PageWrapper component={LessonUpdatePage} componentOther={LessonCreatePage} />
));

export default LessonPage;
