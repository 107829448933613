import React from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  component: React.ElementType;
  componentOther: React.ElementType;
};

function PageWrapper({
  component: Component,
  componentOther: ComponentOther,
}: Props) {
  const { id } = useParams();

  if (!id) {
    return <ComponentOther />;
  }

  return <Component />;
}

export default PageWrapper;
