import React, { Skeleton } from '@mui/material';
import { memo } from 'react';

const PageLoader = memo(() => (
  <Skeleton
    animation="wave"
    variant="rectangular"
    height="100vh"
    width="100vw"
  />
));

export default PageLoader;
