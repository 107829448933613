import React, { forwardRef, memo } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import { IAdminCreateRequest } from '../types';
import { LoginText } from '../../Login/constants';

interface ICreateAdminFormProps {
  onSubmit: (data: IAdminCreateRequest) => void;
  setFormValid: (isValid: boolean) => void;
}

const CreateAdminForm = forwardRef<any, ICreateAdminFormProps>(
  ({ onSubmit, setFormValid }, ref) => {
    const {
      handleSubmit,
      register,
      formState: { isDirty, isValid, errors },
    } = useForm<IAdminCreateRequest>();

    return (
      <Box
        ref={ref}
        onChange={() => setFormValid(isDirty && isValid)}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1 }}
      >
        <TextField
          autoFocus
          margin="dense"
          {...register('email')}
          {...(errors.email
            ? { error: true, helperText: errors.email.message }
            : {})}
          label={LoginText.EMAIL}
          type="email"
          fullWidth
          variant="standard"
        />
      </Box>
    );
  },
);

export default memo(CreateAdminForm);
