import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { getUserList } from '../services';
import { IUserListResponse } from '../types';
import { IUserResponse } from '../../../types';

export interface IUsersState {
  isLoading: boolean;
  users: IUserResponse[];
  page: number;
  pageSize: number;
  count: number | null;
  loadMore: boolean;
}

const initialState: IUsersState = {
  isLoading: false,
  users: [],
  page: 1,
  pageSize: 10,
  count: null,
  loadMore: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getUserList.fulfilled,
      (state, { payload }: PayloadAction<IUserListResponse>) => {
        const { count, list } = payload;
        state.isLoading = false;
        state.count = count;
        state.users = state.page > 1 ? [...state.users, ...list] : list;
        state.loadMore = state.users.length !== count;
      },
    );
    builder.addCase(getUserList.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setPage, setPageSize } = usersSlice.actions;
export default usersSlice.reducer;
