import axios from 'axios';
import Cookies from 'js-cookie';
import {
  getStorageValue,
  removeStorageValue,
  setStorageValue,
} from '../../utils/helpers';
import {
  COOKIE_ACCESS_TOKEN_NAME,
  LOCAL_STORAGE_REFRESH_TOKEN_NAME,
} from '../../utils/constants';
import config from '../../config';
import { API_ROUTES } from '../router/apiRoutes';

const refreshTokenFn = async () => {
  const refreshToken = getStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME);

  try {
    const { data } = await axios.post(
      API_ROUTES.REFRESH_TOKEN,
      {
        refreshToken,
      },
      {
        baseURL: config.restApiHost,
      },
    );

    if (!data?.accessToken || !data?.refreshToken) {
      removeStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
      Cookies.remove(COOKIE_ACCESS_TOKEN_NAME);
    }

    setStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, data.refreshToken);
    Cookies.set(COOKIE_ACCESS_TOKEN_NAME, data.accessToken);

    return data.accessToken;
  } catch (error) {
    removeStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
    Cookies.remove(COOKIE_ACCESS_TOKEN_NAME);
  }

  return '';
};

export const refreshAccessToken = refreshTokenFn;
