import { Button, Paper, TextField } from '@mui/material';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TrainerFormLayout, TrainerFormField } from '../../styles';
import { ICreateTrainerSubmit, ITrainerForm } from '../../types';
import { pageSize } from '../../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  getAllCoursesSelector,
  getCoursesLoadMoreSelector,
} from '../../selectors';
import { getAllCourses } from '../../service';
import ScrollSelect from '../../../../components/ScrollSelect';

interface TrainerFormProps {
  form?: ITrainerForm;
  oldAvatar?: string | null;
  onSubmit: (data: ICreateTrainerSubmit) => void;
}

const TrainerForm: FC<TrainerFormProps> = memo(
  ({ onSubmit, form, oldAvatar }) => {
    const {
      register,
      handleSubmit,
      control,
      formState: { isDirty, isValid, errors },
    } = useForm<ITrainerForm>({
      values: {
        name: form?.name,
        description: form?.description,
        position: form?.position,
        courses: form?.courses,
        file: form?.file,
      },
    });
    const dispatch = useAppDispatch();
    const courses = useAppSelector(getAllCoursesSelector);
    const loadMoreStatus = useAppSelector(getCoursesLoadMoreSelector);
    const [page, setPage] = useState<number>(1);
    // const [loadOptions, setLoadOptions] = useState<boolean>(false);

    const statusField = () => (form ? { shrink: true } : {});

    const getCourses = useCallback(() => {
      dispatch(getAllCourses({ page, pageSize, isDraft: false }));
    }, [dispatch, page]);

    const handleScroll = useCallback(
      (pageNum: number) => {
        setPage(pageNum);
      },
      [setPage],
    );

    const handleClose = useCallback(() => {
      setPage(1);
    }, []);

    useEffect(() => {
      if (loadMoreStatus) getCourses();
    }, [page]);

    return (
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          minWidth: 345,
          maxWidth: 600,
        }}
      >
        <TrainerFormLayout onSubmit={handleSubmit(onSubmit)}>
          <TrainerFormField>
            <TextField
              label="Name"
              fullWidth
              {...register('name')}
              InputLabelProps={statusField()}
            />
            <div>{errors.name && errors.name.message}</div>
          </TrainerFormField>

          <TrainerFormField>
            <TextField
              label="Position"
              fullWidth
              {...register('position')}
              InputLabelProps={statusField()}
            />
            <div>{errors.position && errors.position.message}</div>
          </TrainerFormField>
          <TrainerFormField>
            <TextField
              label="Description"
              fullWidth
              {...register('description')}
              InputLabelProps={statusField()}
            />
            <div>{errors.description && errors.description.message}</div>
          </TrainerFormField>
          <TrainerFormField>
            <label htmlFor="file">Avatar:</label>
            {oldAvatar && (
              <img
                src={oldAvatar}
                alt="trainer-img"
                style={{ width: '30%', display: 'block' }}
              />
            )}
            <TextField
              fullWidth
              type="file"
              inputProps={{ accept: 'image/jpeg, image/png' }}
              {...register('file')}
            />
            <div>{errors.file && errors.file.message}</div>
          </TrainerFormField>
          <TrainerFormField>
            <label htmlFor="courses">Courses:</label>
            <Controller
              control={control}
              name="courses"
              render={({ field }) => (
                <ScrollSelect
                  {...field}
                  options={courses}
                  handleOpen={getCourses}
                  handleClose={handleClose}
                  page={page}
                  setPage={handleScroll}
                  loadMore={loadMoreStatus}
                  isMulti
                />
              )}
            />
            <div>{errors.courses && errors.courses.message}</div>
          </TrainerFormField>
          {form ? (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty || !isValid}
              aria-label="Update trainer"
              title="Update trainer"
            >
              {' '}
              Update trainer
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty || !isValid}
              aria-label="Create trainer"
              title="Create trainer"
            >
              {' '}
              Create trainer
            </Button>
          )}
        </TrainerFormLayout>
      </Paper>
    );
  },
);

export default TrainerForm;
