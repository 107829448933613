import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { StyledContainer, StyledIconBox, StyledChevronLeft } from './styled';

interface BackLinkProps {
  text: string;
  onClick: () => void;
}

const BackLink = memo(({ text, onClick }: BackLinkProps) => (
  <StyledContainer onClick={onClick}>
    <StyledIconBox>
      <StyledChevronLeft />
    </StyledIconBox>
    <Typography variant="subtitle2" color="GrayText">
      {text}
    </Typography>
  </StyledContainer>
));

export default BackLink;
