import { CardActionArea, Card, Typography } from '@mui/material';
import React, { FC, memo, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { IExerciseOfTheDayItem } from '../../types';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import AlertDialog from '../../../../components/AlertDialog/alert-dialog';
import { useAppDispatch } from '../../../../hooks';
import { deleteExercise } from '../../service';
import { setExercise } from '../../../ExercisePage/slices/slice';

interface IExerciseOfTheDayCardProps {
  exercise: IExerciseOfTheDayItem;
}

const ExerciseOfTheDayCard: FC<IExerciseOfTheDayCardProps> = memo(
  ({ exercise }) => {
    const dispatch = useAppDispatch();

    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [id, setId] = useState('');

    const changeStatus = (exerciseId: string) => {
      setId(exerciseId);
      setOpenDeleteDialog(!openDeleteDialog);
    };

    const handleSubmit = async () => {
      if (id) {
        await dispatch(deleteExercise({ id }));
        setOpenDeleteDialog(false);
      }
    };

    const handleClose = () => {
      setOpenDeleteDialog(false);
    };

    return (
      <>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            maxWidth: 600,
          }}
        >
          <Link to={`${APP_ROUTES.EXERCISES_OF_THE_DAY}/${exercise.id}`}>
            {exercise.previewImage?.url && (
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="240"
                  image={exercise.previewImage.url}
                  alt={exercise.title}
                />
              </CardActionArea>
            )}
          </Link>
          <CardContent style={{ height: '250px', wordWrap: 'break-word' }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ textAlign: 'center' }}
            >
              {exercise.title.length > 35
                ? `${exercise.title.slice(0, 35)}...`
                : exercise.title}
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              component="div"
              sx={{ textAlign: 'center' }}
            >
              {exercise.description.length > 180
                ? `${exercise.description.slice(0, 180)}...`
                : exercise.description}
            </Typography>
          </CardContent>
          <CardActions>
            <Link
              to={`${APP_ROUTES.EXERCISES_OF_THE_DAY}/${exercise.id}`}
              onClick={() => {
                dispatch(setExercise(exercise));
              }}
            >
              <Button size="medium">Edit</Button>
            </Link>
            <Button
              size="medium"
              color="error"
              onClick={() => changeStatus(exercise.id)}
            >
              Delete
            </Button>
          </CardActions>
        </Card>
        {openDeleteDialog && (
          <AlertDialog
            question={`Delete exercise ${exercise.title}?`}
            description="Are you sure you want to delete the exercise?"
            buttonAgree="Delete"
            buttonDisagree="Cancel"
            openStatus={openDeleteDialog}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
          />
        )}
      </>
    );
  },
);

export default ExerciseOfTheDayCard;
