import React, { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { CardContainer, TitleContainer, CardsContainer } from '../../styles';
import ChallengeCard from '../Card';
import { IChallenge } from '../../../ChallengesPage/types';

interface IActivePageProps {
  challenges: IChallenge[];
  changeStatus: (id: string, isDraft: boolean) => void;
}

const ActivePage = memo(({ challenges, changeStatus }: IActivePageProps) => {
  const ongoing = challenges[challenges.length - 1];
  const ended = challenges.filter((challenge) => challenge.id !== ongoing.id);
  return (
    <CardContainer>
      <TitleContainer>
        <Typography>Ongoing banner in App</Typography>
        <CardsContainer>
          <ChallengeCard challenge={ongoing} changeStatus={changeStatus} />
        </CardsContainer>
      </TitleContainer>
      {ended.length ? (
        <TitleContainer>
          <Typography>Ended</Typography>
          <Grid
            container
            justifyContent="left"
            alignItems="left"
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {ended.map((challenge) => (
              <Grid item xs={12} sm={6} md={4} key={challenge.id}>
                <ChallengeCard
                  challenge={challenge}
                  key={challenge.id}
                  changeStatus={changeStatus}
                />
              </Grid>
            ))}
          </Grid>
        </TitleContainer>
      ) : null}
    </CardContainer>
  );
});

export default ActivePage;
