import { createAsyncThunk } from '@reduxjs/toolkit';
import { post, get, patch, del } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import {
  ICreateChallengeRequest,
  IGetAllAchievements,
  IGetAllAchievementsRequest,
  IGetChallengeRequest,
  IUpdateChallengeRequest,
  IUploadFileRequest,
  IUploadFileResponse,
} from '../types';
import {
  IDeleteChallengeRequest,
  ISingleChallenge,
} from '../../Challenges/types';
import { IStatusDelete } from '../../Courses/service';

export interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export const createChallenge = createAsyncThunk<
  ISingleChallenge,
  ICreateChallengeRequest,
  ICommonChunkConfig
>('challenge/createChallenge', async (data, { rejectWithValue }) => {
  try {
    const response = await post({ path: API_ROUTES.CHALLENGES, data });
    return response as ISingleChallenge;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const getAllAchievements = createAsyncThunk<
  IGetAllAchievements,
  IGetAllAchievementsRequest,
  ICommonChunkConfig
>('challenge/fetchAllAchievements', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.ACHIEVEMENTS,
      params: _data,
    });
    return response as IGetAllAchievements;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const uploadImage = createAsyncThunk<
  IUploadFileResponse,
  IUploadFileRequest,
  ICommonChunkConfig
>('challenge/uploadImage', async (data, { rejectWithValue }) => {
  try {
    const { id, file } = data;
    const response = await post(
      {
        path: `${API_ROUTES.CHALLENGES}/${id}/upload-challenge-image`,
        data: { file },
      },
      'multipart/form-data',
    );
    return response as IUploadFileResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const getChallenge = createAsyncThunk<
  ISingleChallenge,
  IGetChallengeRequest,
  ICommonChunkConfig
>('challenge/getChallenge', async (params, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.CHALLENGES}/${params.id}`,
    });
    return response as ISingleChallenge;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateChallenge = createAsyncThunk<
  IUpdateChallengeRequest,
  IUpdateChallengeRequest,
  ICommonChunkConfig
>('challenge/updateChallenge', async (data, { rejectWithValue }) => {
  try {
    const { id, challenge } = data;
    await patch({ path: `${API_ROUTES.CHALLENGES}/${id}`, data: challenge });
    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const deleteChallengeDay = createAsyncThunk<
  IStatusDelete,
  IDeleteChallengeRequest,
  ICommonChunkConfig
>('challenge/deleteChallengeDay', async (params, { rejectWithValue }) => {
  try {
    await del({ path: `${API_ROUTES.CHALLENGES}/challengeDays/${params.id}` });
    return { id: params.id } as IStatusDelete;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
