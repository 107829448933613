import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from '../Form';
import { IChallengeForm } from '../../types';
import { getChallengesLoadingSelector } from '../../selector';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { createChallenge, uploadImage } from '../../service';
import { ToastMessage } from '../../../../utils/constants';
import { ISingleChallenge } from '../../../Challenges/types';
import BackLink from '../../../../components/BackLink';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

const CreateChallenge: FC = () => {
  const isLoading = useAppSelector(getChallengesLoadingSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname as string;
  const isDraftPrevious = from && from.includes('drafts');

  const onSubmit = ({
    description,
    files,
    name,
    shortDescription,
    level,
    startDate,
    finishDate,
    achievementId,
  }: IChallengeForm) => {
    const isFormFull =
      description &&
      files &&
      files.length &&
      name &&
      shortDescription &&
      level &&
      level.length &&
      startDate &&
      finishDate &&
      achievementId;
    if (isFormFull) {
      const levels = level.map((l) => l.value);
      dispatch(
        createChallenge({
          description,
          name,
          shortDescription,
          level: levels,
          startDate,
          finishDate,
          achievementId: achievementId.value,
          isDraft: true,
        }),
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          const { id } = payload as ISingleChallenge;
          if (id) {
            const file = files[0];
            dispatch(
              uploadImage({
                file,
                id,
              }),
            );
          }
        }
      });
    } else {
      toast.warning(ToastMessage.CHECK_ALL_FIELDS);
    }
  };
  const goBack = () => {
    if (isDraftPrevious) {
      navigate(`${APP_ROUTES.CHALLENGES}/drafts`);
    } else {
      navigate(APP_ROUTES.CHALLENGES);
    }
  };

  return (
    <div>
      <BackLink
        text={isDraftPrevious ? 'Back to Drafts' : 'Back to Active'}
        onClick={goBack}
      />
      <h1>New challenge</h1>
      {isLoading ? <p>Loading...</p> : <Form onSubmit={onSubmit} />}
    </div>
  );
};

export default CreateChallenge;
