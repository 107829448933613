import { CardActionArea, Card, Typography } from '@mui/material';
import React, { FC, memo, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Person as PersonIcon } from '@mui/icons-material';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { ITrainer } from '../../types';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import AlertDialod from '../../../../components/AlertDialog/alert-dialog';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getLoadingTrainersSelector } from '../../selectors';
import { deleteTrainer } from '../../service';

export {};

interface TrainerCardProps {
  trainer: ITrainer;
}

const TrainerCard: FC<TrainerCardProps> = memo(({ trainer }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getLoadingTrainersSelector);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [id, setId] = useState('');

  const changeStatus = (trainerId: string) => {
    setId(trainerId);
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const handleSubmit = async () => {
    if (id) {
      await dispatch(deleteTrainer({ id }));
      setOpenDeleteDialog(false);
    }
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          maxWidth: 345,
        }}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <Link to={`${APP_ROUTES.TRAINERS}/${trainer.id}/edit`}>
              <CardActionArea>
                {trainer.avatar.url ? (
                  <CardMedia
                    component="img"
                    height="240"
                    image={trainer.avatar.url}
                    alt={trainer.name}
                  />
                ) : (
                  <PersonIcon />
                )}
              </CardActionArea>
            </Link>
            <CardContent style={{ height: '250px', wordWrap: 'break-word' }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ textAlign: 'center' }}
              >
                {trainer.name.length > 35
                  ? `${trainer.name.slice(0, 35)}...`
                  : trainer.name}
              </Typography>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ textAlign: 'center' }}
              >
                {trainer.position.length > 180
                  ? `${trainer.position.slice(0, 180)}...`
                  : trainer.position}
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: 'center' }}
              >
                {trainer.description.length > 180
                  ? `${trainer.description.slice(0, 180)}...`
                  : trainer.description}
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                component="div"
                sx={{ textAlign: 'center' }}
              >
                Course count:
                {' '}
                {trainer.courses.length ? trainer.courses.length : '0'}
              </Typography>
            </CardContent>
            <CardActions>
              <Link to={`${APP_ROUTES.TRAINERS}/${trainer.id}/edit`}>
                <Button size="medium">Edit</Button>
              </Link>
              <Button
                size="medium"
                color="error"
                onClick={() => changeStatus(trainer.id)}
              >
                Delete
              </Button>
            </CardActions>
          </>
        )}
      </Card>
      {openDeleteDialog && (
        <AlertDialod
          question="Delete trainer?"
          description="This action is irreversible"
          buttonAgree="Delete"
          buttonDisagree="Cancel"
          openStatus={openDeleteDialog}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      )}
    </>
  );
});

export default TrainerCard;
