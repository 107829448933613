import { createAsyncThunk } from '@reduxjs/toolkit';
import { IResetUserPasswordRequest, IUserRequest } from '../types';
import { IErrorResponse, IUserResponse } from '../../../types';
import { get, post } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export const fetchUser = createAsyncThunk<
IUserResponse,
IUserRequest,
ICommonChunkConfig
>('user/fetchUser', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.USERS}/${_data.id}`,
      params: {},
    });
    return response as IUserResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const resetUserPassword = createAsyncThunk<
null,
IResetUserPasswordRequest,
ICommonChunkConfig
>('user/resetPassword', async (_data, { rejectWithValue }) => {
  try {
    return await post({ path: API_ROUTES.RESET_USER_PASSWORD, data: _data });
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
