import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getExercisesOfTheDay } from './service';
import { getExercisesOfTheDaySelector } from './selector';
import { pageSize } from '../../utils/constants';
import ExercisesOfTheDayPage from './Page';
import { APP_ROUTES } from '../../core/router/appRoutes';

export interface ExerciseOfTheDayProps {
  isDraft: boolean;
}

const ExerciseOfTheDay: FC<ExerciseOfTheDayProps> = ({ isDraft }) => {
  const dispatch = useAppDispatch();
  const exercises = useAppSelector(getExercisesOfTheDaySelector);

  const navigate = useNavigate();

  const [, setPage] = useState<number>(1);

  const onDraftClickHandler = () => {
    if (!isDraft) {
      navigate(`${APP_ROUTES.EXERCISES_OF_THE_DAY}/drafts`);
    } else {
      navigate(`${APP_ROUTES.EXERCISES_OF_THE_DAY}`);
    }
  };

  useEffect(() => {
    dispatch(getExercisesOfTheDay({ page: 1, pageSize, isDraft }));
  }, [isDraft]);

  return (
    <ExercisesOfTheDayPage
      onDraftClick={onDraftClickHandler}
      exercisesSection={isDraft ? 'Open active exercises' : 'Open drafts'}
      exercises={exercises}
      onClickLoadMore={() =>
        setPage((prev) => {
          const page = prev + 1;
          dispatch(getExercisesOfTheDay({ page, pageSize, isDraft }));
          return page;
        })
      }
    />
  );
};

export default ExerciseOfTheDay;
