import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { fetchUser, resetUserPassword } from '../services';
import { IUserResponse } from '../../../types';

export interface IUsersState {
  isLoading: boolean;
  currentUser: IUserResponse | null;
}

const initialState: IUsersState = {
  isLoading: false,
  currentUser: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      { payload }: PayloadAction<IUserResponse | null>,
    ) => {
      state.currentUser = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchUser.fulfilled,
      (state, { payload }: PayloadAction<IUserResponse>) => {
        state.isLoading = false;
        state.currentUser = payload;
      },
    );
    builder.addCase(fetchUser.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(resetUserPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetUserPassword.fulfilled, (state) => {
      state.isLoading = false;
      toast("Password send to user's email");
    });
    builder.addCase(resetUserPassword.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setCurrentUser } = userSlice.actions;
export default userSlice.reducer;
