import React, { memo } from 'react';
import { IChallengeForm, Level } from '../../types';
import { ISingleChallenge } from '../../../Challenges/types';
import Form from '../Form';
import ChallengeDaysPage from '../../../ChallengeDays';

interface IPageWrapperProps {
  isDetails: boolean;
  challenge: ISingleChallenge | null;
  onSubmit: (form: IChallengeForm) => void;
  image: string;
}

const PageWrapper = memo(
  ({ isDetails, challenge, onSubmit, image }: IPageWrapperProps) => (isDetails ? (
    <Form
      onSubmit={onSubmit}
      form={{
        name: challenge?.name,
        description: challenge?.description,
        shortDescription: challenge?.shortDescription,
        level: challenge?.level?.map((level: Level) => ({
          label: level,
          value: level,
        })),
        startDate: challenge?.startDate
          ? new Date(challenge?.startDate)
          : null,
        finishDate: challenge?.finishDate
          ? new Date(challenge?.finishDate)
          : null,
        achievementId: {
          label: challenge?.achievement.name || '',
          value: challenge?.achievement.id || '',
        },
      }}
      oldImage={image}
    />
  ) : (
    <ChallengeDaysPage challenge={challenge} />
  )),
);

export default PageWrapper;
