import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IAdminCreateRequest,
  IAdminDeleteRequest,
  IAdminListResponse,
  IStatusDelete,
} from '../types';
import { IErrorResponse, IListRequest, IUserResponse } from '../../../types';
import { get, post, del } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export const getAdminList = createAsyncThunk<
  IAdminListResponse,
  IListRequest,
  ICommonChunkConfig
>('admins/fetchAdminList', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({ path: API_ROUTES.ADMINS, params: _data });
    return response as IAdminListResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const createAdmin = createAsyncThunk<
  IUserResponse,
  IAdminCreateRequest,
  ICommonChunkConfig
>('admins/createAdmin', async (_data, { rejectWithValue }) => {
  try {
    const response = await post({ path: API_ROUTES.ADMINS, data: _data });
    return response as IUserResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const removeAdmin = createAsyncThunk<
  IStatusDelete,
  IAdminDeleteRequest,
  ICommonChunkConfig
>('admins/removeAdmin', async (params, { rejectWithValue }) => {
  try {
    await del({ path: `${API_ROUTES.USERS}/${params.id}`, params: {} });
    return { id: params.id } as IStatusDelete;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
