import { createAsyncThunk } from '@reduxjs/toolkit';
import { del, get } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import { IExerciseOfTheDayResponse } from '../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export interface IStatusDelete {
  id: string;
}

export interface IExercisesOfTheDayParams {
  page: number;
  pageSize: number;
  isDraft: boolean;
}

export const getExercisesOfTheDay = createAsyncThunk<
  IExerciseOfTheDayResponse,
  IExercisesOfTheDayParams,
  ICommonChunkConfig
>(
  'exercisesOfTheDay/fetchExercisesOfTheDay',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await get({
        path: `${API_ROUTES.EXERCISE_OF_THE_DAY}/all`,
        params: _data,
      });
      return {
        ...response,
        page: _data.page,
        pageSize: _data.pageSize,
      } as IExerciseOfTheDayResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const deleteExercise = createAsyncThunk<
  IStatusDelete,
  IStatusDelete,
  ICommonChunkConfig
>('exercisesOfTheDay/deleteExercise', async (params, { rejectWithValue }) => {
  try {
    await del({ path: `${API_ROUTES.EXERCISE_OF_THE_DAY}/${params.id}` });
    return { id: params.id } as IStatusDelete;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
