import React, { FC, memo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../hooks';
import TrainerListCards from './components/TrainerListCards';
import {
  getErrorsTrainersSelector,
  getLoadingTrainersSelector,
  getTrainersSelector,
} from './selectors';
import { getTrainers } from './service';

const Trainers: FC = memo(() => {
  const dispatch = useAppDispatch();
  const coursesResponse = useAppSelector(getTrainersSelector);
  const isLoading = useAppSelector(getLoadingTrainersSelector);
  const errors = useAppSelector(getErrorsTrainersSelector);

  useEffect(() => {
    if (errors.length) {
      errors.forEach((e: string) => {
        toast.error(e);
      });
    }
    dispatch(getTrainers());
  }, [errors]);

  return (
    <div>
      <h1>Trainers:</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <TrainerListCards trainers={coursesResponse.trainers} />
      )}
    </div>
  );
});

export default Trainers;
