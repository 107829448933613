import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  getChallengeSelector,
  getChallengesLoadingSelector,
  getChallengeUrlSelector,
} from '../../selector';
import { getChallenge, updateChallenge, uploadImage } from '../../service';
import { IChallengeForm } from '../../types';
import { ToastMessage } from '../../../../utils/constants';
import FormTabs from '../Tabs';
import PageWrapper from '../PageWrapper';
import BackLink from '../../../../components/BackLink';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { changePublishStatus } from '../../../Challenges/service';

interface IUpdateChallengesProps {
  details?: boolean;
}

const UpdateChallenge = ({ details }: IUpdateChallengesProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const isLoading = useAppSelector(getChallengesLoadingSelector);
  const challenge = useAppSelector(getChallengeSelector);
  const image = useAppSelector(getChallengeUrlSelector);
  const [isDetails, setIsDetails] = useState(!!details);

  useEffect(() => {
    setIsDetails(!!details);
  }, [details]);

  useEffect(() => {
    if (id) {
      dispatch(getChallenge({ id }));
    }
  }, []);

  const onSubmit = (form: IChallengeForm) => {
    const {
      description,
      files,
      name,
      shortDescription,
      level,
      startDate,
      finishDate,
      achievementId,
    } = form;
    const isFormFull =
      description &&
      name &&
      shortDescription &&
      level &&
      level.length &&
      startDate &&
      finishDate &&
      achievementId;
    const isFileAttached =
      (files && files.length) || (challenge?.image && challenge?.image.url);

    if (id && isFormFull && isFileAttached) {
      const levels = level.map((l) => l.value);
      dispatch(
        updateChallenge({
          challenge: {
            description,
            name,
            shortDescription,
            level: levels,
            startDate,
            finishDate,
            achievementId: achievementId.value,
            isDraft: true,
          },
          id,
        }),
      ).then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          dispatch(getChallenge({ id }));
        }
      });

      if (files && files.length) {
        const file = files[0];
        dispatch(
          uploadImage({
            file,
            id,
          }),
        );
      }
    } else {
      toast.warning(ToastMessage.CHECK_ALL_FIELDS);
    }
  };

  const goBack = () => {
    navigate(`${APP_ROUTES.CHALLENGES}/drafts`);
  };

  const publishChallenge = useCallback(() => {
    if (id) {
      dispatch(changePublishStatus({ id, isDraft: false })).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          goBack();
        }
      });
    }
  }, [changePublishStatus]);

  return (
    <div>
      <BackLink text="Back to Drafts" onClick={goBack} />
      <h1>Update challenge</h1>
      <FormTabs
        isActive={isDetails}
        challenge={challenge}
        publishChallenge={publishChallenge}
      />
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <PageWrapper
          challenge={challenge}
          onSubmit={onSubmit}
          isDetails={isDetails}
          image={image}
        />
      )}
    </div>
  );
};

export default UpdateChallenge;
