import React, { memo } from 'react';
import { NoChallenges, GrayText } from '../../styles';
import PageWrapper from '../PageWrapper';
import { IChallenge } from '../../../ChallengesPage/types';

interface IChallengesPageProps {
  challenges: IChallenge[];
  isActive: boolean;
  changeStatus: (id: string, isDraft: boolean) => void;
  deleteChallenge: (id: string) => void;
}

const ChallengesPage = memo(
  ({
    challenges,
    isActive,
    changeStatus,
    deleteChallenge,
  }: IChallengesPageProps) => (challenges.length ? (
    <PageWrapper
      isActive={isActive}
      challenges={challenges}
      changeStatus={changeStatus}
      deleteChallenge={deleteChallenge}
    />
  ) : (
    <NoChallenges>
      <GrayText>There are no Challenges here yet</GrayText>
    </NoChallenges>
  )),
);

export default ChallengesPage;
