import React, { useEffect } from 'react';
import { UseFormResetField } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import ExerciseForm from '../../Form';
import {
  getExerciseErrorsSelector,
  getExerciseSelector,
  getLoadingExerciseSelector,
} from '../../selector';
import {
  getExercise,
  updateExercise,
  updatePreviewImage,
  updateAffirmationImage,
  updateExerciseVideo,
  updateVideoDurationByVideoId,
} from '../../service';
import { IExerciseForm } from '../../Form/Form';

const ExerciseUpdatePage = () => {
  const dispatch = useAppDispatch();
  const exercise = useAppSelector(getExerciseSelector);
  const isLoading = useAppSelector(getLoadingExerciseSelector);
  const errors = useAppSelector(getExerciseErrorsSelector);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getExercise({ id }));
    }

    if (errors.length) {
      errors.forEach((e: string) => {
        toast.error(e);
      });
    }
  }, [id, dispatch, errors]);

  const onSubmitUpdate = (
    data: IExerciseForm,
    resetField: UseFormResetField<IExerciseForm>,
  ) => {
    const {
      title,
      description,
      isDraft,
      showTitle,
      affirmationImageFiles,
      duration,
      position,
      previewImageFiles,
      videoFiles,
    } = data;

    if (id) {
      dispatch(
        updateExercise({
          data: {
            description,
            title,
            isDraft,
            showTitle,
            ...(position && { position: position && +position }),
          },
          id,
        }),
      ).then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          if (previewImageFiles?.length) {
            dispatch(
              updatePreviewImage({
                file: previewImageFiles[0],
                id,
              }),
            );
          }

          if (videoFiles?.length) {
            dispatch(
              updateExerciseVideo({
                id,
                file: videoFiles[0],
                duration,
              }),
            ).then(({ meta: { requestStatus } }) => {
              if (requestStatus === 'fulfilled') {
                resetField('videoFiles');
              }
            });
          }
          if (
            exercise?.video?.id &&
            !videoFiles?.length &&
            duration &&
            duration !== exercise?.video?.duration
          ) {
            dispatch(
              updateVideoDurationByVideoId({
                id: exercise?.video?.id,
                duration: duration && +duration,
              }),
            );
          }

          if (affirmationImageFiles?.length) {
            dispatch(
              updateAffirmationImage({
                file: affirmationImageFiles[0],
                id,
              }),
            );
          }
        }
      });
    } else {
      toast.warning('No exercise id');
    }
  };

  return (
    <div>
      <h1>Update exercise</h1>
      {exercise && (
        <ExerciseForm
          onSubmit={onSubmitUpdate}
          type="update"
          form={{ ...exercise, duration: exercise.video?.duration }}
          oldAffirmationImage={exercise?.affirmationImage?.url}
          oldPreview={exercise?.previewImage?.url}
          oldVideoPreview={exercise?.video?.url}
          isLoading={isLoading}
        />
      )}
      {isLoading && <p>loading...</p>}
    </div>
  );
};

export default ExerciseUpdatePage;
