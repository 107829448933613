import { createAsyncThunk } from '@reduxjs/toolkit';
import { del, get, post } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import {
  IChangePublishStatus,
  IDeleteChallengeRequest,
  IGetChallengesRequest,
  IGetChallengesResponse,
} from '../types';
import { IStatusDelete } from '../../Courses/service';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export const getChallenges = createAsyncThunk<
IGetChallengesResponse,
IGetChallengesRequest,
ICommonChunkConfig
>('challenges/getChallenges', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({ path: API_ROUTES.CHALLENGES, params: _data });
    return response as IGetChallengesResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const changePublishStatus = createAsyncThunk<
IChangePublishStatus,
IChangePublishStatus,
ICommonChunkConfig
>('challenges/changePublishStatus', async (params, { rejectWithValue }) => {
  try {
    const { isDraft, id } = params;
    await post({
      path: `${API_ROUTES.CHALLENGES}/${id}/publish`,
      data: { isDraft },
    });
    return { isDraft, id } as IChangePublishStatus;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const deleteChallenge = createAsyncThunk<
IStatusDelete,
IDeleteChallengeRequest,
ICommonChunkConfig
>('challenges/deleteChallenge', async (params, { rejectWithValue }) => {
  try {
    await del({ path: `${API_ROUTES.CHALLENGES}/${params.id}` });
    return { id: params.id } as IStatusDelete;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
