import React, { FC, memo } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { TrainersListCards, TrainersListCardsHeader } from '../../styles';
import { ITrainer } from '../../types';
import TrainerCard from '../TrainerCard';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

interface TrainerListCardsProps {
  trainers: ITrainer[];
}

const TrainerListCards: FC<TrainerListCardsProps> = memo(({ trainers }) => (
  <TrainersListCards>
    <TrainersListCardsHeader>
      <Link to={`${APP_ROUTES.TRAINERS}/create`}>
        <Button size="medium" variant="contained">
          Create trainer
        </Button>
      </Link>
    </TrainersListCardsHeader>
    <Box>
      <Grid
        container
        justifyContent="left"
        alignItems="left"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {trainers.length &&
          trainers.map((trainer) => (
            <Grid item xs={12} sm={6} md={4} key={trainer.id}>
              <TrainerCard trainer={trainer} />
            </Grid>
          ))}
      </Grid>
    </Box>
  </TrainersListCards>
));

export default TrainerListCards;
