import React from 'react';
import { useParams } from 'react-router-dom';
import CreateDay from './components/CreateDay';
import UpdateDay from './components/UpdateDay';

const ChallengeDaysPage = () => {
  const { taskId } = useParams();
  if (!taskId) {
    return <CreateDay />;
  }

  return <UpdateDay />;
};

export default ChallengeDaysPage;
