import React, { memo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getPage,
  getPageSize,
  getUsers,
  getUsersLoading,
  getUsersLoadMore,
} from './selectors';
import { getUserList } from './services';
import { setPage } from './slices';
import UsersWrapper from './UsersWrapper';
import { IUserResponse } from '../../types';
import { APP_ROUTES } from '../../core/router/appRoutes';

const Users = memo(() => {
  const users = useAppSelector(getUsers);
  const loadMore = useAppSelector(getUsersLoadMore);
  const isLoading = useAppSelector(getUsersLoading);
  const page = useAppSelector(getPage);
  const pageSize = useAppSelector(getPageSize);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserList({ page, pageSize }));
  }, [page, pageSize]);

  const onClickHandler = useCallback((user: IUserResponse) => {
    navigate(APP_ROUTES.USER.replace(':id', user.id));
  }, []);

  const onNextClickHandler = useCallback(() => {
    dispatch(setPage(page + 1));
  }, [dispatch, page]);

  return (
    <UsersWrapper
      onClickHandler={onClickHandler}
      users={users}
      isLoading={isLoading}
      loadMore={loadMore}
      onNextClick={onNextClickHandler}
    />
  );
});

export default Users;
