import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserListResponse } from '../types';
import { IErrorResponse, IListRequest } from '../../../types';
import { get } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export const getUserList = createAsyncThunk<
IUserListResponse,
IListRequest,
ICommonChunkConfig
>('users/fetchUserList', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({ path: API_ROUTES.USERS, params: _data });
    return response as IUserListResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
