import React, { memo } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { GrayText } from '../../../Challenges/styles';
import { AddDays } from '../../styled';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

interface IAddDayCardProps {
  daysLeft: number;
  challengeId: string;
}

const AddDayCard = memo(({ daysLeft, challengeId }: IAddDayCardProps) => (
  <AddDays>
    <GrayText>
      {`Add the tasks of the day for each day of the Challenge (${daysLeft} ${
        daysLeft === 1 ? 'day' : 'days'
      } left to add)`}
    </GrayText>
    <Link to={`${APP_ROUTES.CHALLENGES}/${challengeId}/tasks/create`}>
      <Button variant="contained">+ ADD DAY</Button>
    </Link>
  </AddDays>
));

export default AddDayCard;
