import React, { memo, ReactNode } from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table as MiuTable,
} from '@mui/material';

interface IRowItem<T> {
  value: T;
  align?: 'center' | 'left' | 'right' | 'justify' | 'inherit' | undefined;
}

interface ITableProps {
  size?: 'small' | 'medium' | undefined;
  head: IRowItem<string>[];
  rows: ReactNode[];
}

const Table = memo<ITableProps>(({ size = 'small', head, rows }) => (
  <MiuTable size={size}>
    <TableHead>
      <TableRow>
        {head.map((headItem) => (
          <TableCell
            key={headItem.value}
            {...(headItem.align ? { align: headItem.align } : {})}
          >
            {headItem.value}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>{rows}</TableBody>
  </MiuTable>
));

export default Table;
