import { Box, Button, Grid } from '@mui/material';
import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../core/router/appRoutes';
import { CoursesSection } from '../../../utils/constants';
import CourseCard from '../components/Card';
import { ICourseItem } from '../types';

export interface CoursesProp {
  courses: ICourseItem[];
  loadMoreStatus: boolean;
  courseSection: CoursesSection;
  updatePage: (page: number) => void;
  onDraftClick: () => void;
}

const CoursesPage: FC<CoursesProp> = memo(
  ({ courses, updatePage, courseSection, loadMoreStatus, onDraftClick }) => (
    <>
      <div>
        <Button
          size="medium"
          variant="contained"
          style={{ marginRight: '10' }}
          onClick={() => onDraftClick()}
        >
          {courseSection}
        </Button>{' '}
        <Link to={`${APP_ROUTES.COURSES}/create`}>
          <Button size="medium" variant="contained">
            Create course
          </Button>
        </Link>
      </div>
      <Box marginTop={5} marginBottom={5}>
        <Grid
          container
          justifyContent="left"
          alignItems="left"
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {courses.length &&
            courses.map((course) => (
              <Grid item xs={12} sm={6} md={4} key={course.id}>
                <CourseCard course={course} />
              </Grid>
            ))}
        </Grid>
      </Box>
      {loadMoreStatus ? (
        <Button
          size="medium"
          variant="contained"
          onClick={() => {
            updatePage(1);
          }}
        >
          Load more
        </Button>
      ) : (
        <span>All courses loaded</span>
      )}
    </>
  ),
);

export default CoursesPage;
