import React, { memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { IAchievementForm } from '../../types';
import { FormLayout, StyledPaper } from '../../styled';
import { fetchJson } from '../../../Achievements/utils';
import { IAchievementImage } from '../../../Achievements/types';

interface IFromProps {
  form?: IAchievementForm;
  onSubmit: (data: IAchievementForm) => void;
  oldImage?: IAchievementImage | null;
}

const Form = memo(({ form, onSubmit, oldImage }: IFromProps) => {
  const [json, setJson] = useState(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm<IAchievementForm>({
    values: {
      name: form?.name,
      description: form?.description,
      files: form?.files,
    },
  });
  const files = watch('files');
  const statusField = () => (form ? { shrink: true } : {});

  const getJsonData = useCallback(async () => {
    if (oldImage && oldImage.path.endsWith('.json')) {
      const response = await fetchJson(oldImage.url);
      setJson(response);
    } else {
      setJson(null);
    }
  }, [oldImage]);

  useEffect(() => {
    if (form) {
      getJsonData();
    }
  }, [getJsonData, form]);

  return (
    <StyledPaper elevation={3}>
      <FormLayout onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextField
            label="Name"
            fullWidth
            {...register('name')}
            InputLabelProps={statusField()}
          />
          <span>{errors.name && errors.name.message}</span>
        </div>
        <div>
          <TextField
            label="Description"
            fullWidth
            multiline
            {...register('description')}
            InputLabelProps={statusField()}
          />
          <span>{errors.name && errors.name.message}</span>
        </div>
        <div>
          <label htmlFor="file">Achievement image</label>
          <TextField
            fullWidth
            type="file"
            inputProps={{ accept: 'image/jpeg, image/png, application/json' }}
            {...register('files')}
          />
          <Typography variant="subtitle2" color="GrayText" mt={1} mb={1}>
            Upload a json file with an aspect ratio of 1:1
          </Typography>
          {json && !files?.length ? (
            <Lottie
              animationData={json}
              loop
              style={{ width: '160px', height: '160px' }}
            />
          ) : null}
          {oldImage && !oldImage.path.endsWith('.json') && !files?.length && (
            <img
              src={oldImage.url}
              alt="course-preview-img"
              style={{ width: '30%' }}
            />
          )}
          <span>{errors.files && errors.files.message}</span>
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isDirty || !isValid}
        >
          {form ? 'Update achievement' : 'Create achievement'}
        </Button>
      </FormLayout>
    </StyledPaper>
  );
});

export default Form;
