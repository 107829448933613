import React, { FC, memo } from 'react';
import PageWrapper from '../Wrapper';
import TrainerCreatePage from './page/create';
import TrainerUpdatePage from './page/update';

const TrainerPage: FC = memo(() => (
  <PageWrapper
    component={TrainerUpdatePage}
    componentOther={TrainerCreatePage}
  />
));

export default TrainerPage;
