import styled from 'styled-components';
import { colors } from '@mui/material';

export const AddDays = styled.div`
  width: 100%;
  border-radius: 12px;
  padding: 24px;
  background-color: ${colors.common.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`;
