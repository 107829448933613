import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { getAccessToken, getCurrentUser, login, logout } from '../services';
import { ILoginResponse } from '../types';
import { removeStorageValue, setStorageValue } from '../../../utils/helpers';
import {
  COOKIE_ACCESS_TOKEN_NAME,
  LOCAL_STORAGE_REFRESH_TOKEN_NAME,
} from '../../../utils/constants';
import {
  ICurrentUserResponse,
  ITokenResponse,
  IUser,
  Role,
} from '../../../types';

export interface IAuthState {
  isLoading: boolean;
  isLogIn: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  user: IUser | null;
  isAdmin: boolean;
  isSuperAdmin: boolean;
}

const initialState: IAuthState = {
  isLoading: false,
  isLogIn: false,
  accessToken: null,
  refreshToken: null,
  isAdmin: false,
  isSuperAdmin: false,
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      login.fulfilled,
      (state, { payload }: PayloadAction<ILoginResponse>) => {
        const { accessToken, refreshToken } = payload;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        Cookies.set(COOKIE_ACCESS_TOKEN_NAME, accessToken);
        setStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken);
        state.isLoading = false;
        state.isLogIn = true;
      },
    );
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      Cookies.remove(COOKIE_ACCESS_TOKEN_NAME);
      removeStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
      state.isAdmin = initialState.isAdmin;
      state.isSuperAdmin = initialState.isSuperAdmin;
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
      state.user = initialState.user;
      state.isLoading = initialState.isLoading;
      state.isLogIn = initialState.isLogIn;
    });
    builder.addCase(logout.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCurrentUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getCurrentUser.fulfilled,
      (state, { payload }: PayloadAction<ICurrentUserResponse>) => {
        state.isLoading = false;
        state.user = payload;
        state.isAdmin = payload.roles.includes(Role.ADMIN);
        state.isSuperAdmin = payload.roles.includes(Role.SUPER_ADMIN);
      },
    );
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAccessToken.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      getAccessToken.fulfilled,
      (state, { payload }: PayloadAction<ITokenResponse>) => {
        state.isLoading = false;
        const { accessToken, refreshToken } = payload;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        Cookies.set(COOKIE_ACCESS_TOKEN_NAME, accessToken);
        setStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken);
      },
    );
    builder.addCase(getAccessToken.rejected, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        const { statusCode, message } = payload;
        if (statusCode === 400 && message === 'INVALID_REFRESH_TOKEN') {
          state.accessToken = initialState.accessToken;
          state.refreshToken = initialState.refreshToken;
          Cookies.remove(COOKIE_ACCESS_TOKEN_NAME);
          removeStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
          state.user = initialState.user;
          state.isAdmin = initialState.isAdmin;
          state.isSuperAdmin = initialState.isSuperAdmin;
          state.isLogIn = initialState.isLogIn;
        }
      }
    });
  },
});

export const { setAccessToken } = authSlice.actions;
export default authSlice.reducer;
