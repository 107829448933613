import React, { FC, useMemo } from 'react';
import {
  People as PeopleIcon,
  Toc as TocIcon,
  SportsGymnastics as SportsGymnasticsIcon,
  EmojiEvents,
  Flag,
  Star,
} from '@mui/icons-material';

import { useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../../../router/appRoutes';
import { IUser, Role } from '../../../../types';
import Item from '../components/MenuItem';

interface IMenuProps {
  currentUser: IUser;
}

export const Menu: FC<IMenuProps> = ({ currentUser }) => {
  const { pathname } = useLocation();
  const isSuperAdmin = useMemo(
    () => currentUser.roles.includes(Role.SUPER_ADMIN),
    [currentUser],
  );
  return (
    <>
      <Item
        route={APP_ROUTES.COURSES}
        text="Courses"
        icon={
          <TocIcon
            color={pathname === APP_ROUTES.COURSES ? 'primary' : 'inherit'}
          />
        }
        isSelected={pathname === APP_ROUTES.COURSES}
      />
      <Item
        route={APP_ROUTES.TRAINERS}
        text="Trainers"
        icon={
          <SportsGymnasticsIcon
            color={pathname === APP_ROUTES.TRAINERS ? 'primary' : 'inherit'}
          />
        }
        isSelected={pathname === APP_ROUTES.TRAINERS}
      />
      <Item
        route={APP_ROUTES.CHALLENGES}
        text="Challenges"
        icon={
          <Flag
            color={pathname === APP_ROUTES.CHALLENGES ? 'primary' : 'inherit'}
          />
        }
        isSelected={pathname === APP_ROUTES.CHALLENGES}
      />
      <Item
        route={APP_ROUTES.EXERCISES_OF_THE_DAY}
        text="Exercises of the day"
        icon={
          <Star
            color={
              pathname === APP_ROUTES.EXERCISES_OF_THE_DAY
                ? 'primary'
                : 'inherit'
            }
          />
        }
        isSelected={pathname === APP_ROUTES.EXERCISES_OF_THE_DAY}
      />
      <Item
        route={APP_ROUTES.ACHIEVEMENTS}
        text="Achievements"
        icon={
          <EmojiEvents
            color={pathname === APP_ROUTES.ACHIEVEMENTS ? 'primary' : 'inherit'}
          />
        }
        isSelected={pathname === APP_ROUTES.ACHIEVEMENTS}
      />
      <Item
        route={APP_ROUTES.USERS}
        text="Users"
        icon={
          <PeopleIcon
            color={pathname === APP_ROUTES.USERS ? 'primary' : 'inherit'}
          />
        }
        isSelected={pathname === APP_ROUTES.USERS}
      />
      {isSuperAdmin && (
        <Item
          route={APP_ROUTES.ADMINS}
          text="Admins"
          icon={
            <PeopleIcon
              color={pathname === APP_ROUTES.ADMINS ? 'primary' : 'inherit'}
            />
          }
          isSelected={pathname === APP_ROUTES.ADMINS}
        />
      )}
    </>
  );
};
