import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../core/router/appRoutes';

const Home = memo(() => {
  const navigate = useNavigate();

  return (
    <div>
      <h1> HOME </h1>
      <div>
        <button type="button" onClick={() => navigate(APP_ROUTES.ABOUT_US)}>
          About us
        </button>
        <button type="button" onClick={() => navigate(APP_ROUTES.COURSES)}>
          Courses
        </button>
      </div>
    </div>
  );
});

export default Home;
