import React, { memo } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { HeaderContainer, NoChallenges, GrayText } from '../../styles';
import { IAchievement } from '../../types';
import AchievementCard from '../Card';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

interface AchievementsProps {
  achievements: IAchievement[];
  deleteAchievement: (id: string) => void;
  updatePage: () => void;
  loadMoreStatus: boolean;
}

const AchievementsPage = memo(
  ({
    achievements,
    deleteAchievement,
    loadMoreStatus,
    updatePage,
  }: AchievementsProps) => (
    <>
      <HeaderContainer>
        <h1>Achievements:</h1>
        <Link to={`${APP_ROUTES.ACHIEVEMENTS}/create`}>
          <Button variant="contained">+ CREATE NEW ACHIEVEMENT</Button>
        </Link>
      </HeaderContainer>
      {achievements.length ? (
        <div>
          <Box mb={5}>
            <Grid
              container
              justifyContent="left"
              alignItems="left"
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {achievements.map((achievement) => (
                <Grid item xs={12} sm={6} md={4} key={achievement.id}>
                  <AchievementCard
                    achievement={achievement}
                    deleteAchievement={deleteAchievement}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          {loadMoreStatus ? (
            <Button size="medium" variant="contained" onClick={updatePage}>
              Load more
            </Button>
          ) : (
            <span>All achievements loaded</span>
          )}
        </div>
      ) : (
        <NoChallenges>
          <GrayText>There are no Achievements here yet</GrayText>
        </NoChallenges>
      )}
    </>
  ),
);

export default AchievementsPage;
