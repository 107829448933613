import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../containers/Login/slices';
import coursesReducer from '../containers/Courses/slices';
import courseReducer from '../containers/CoursePage/slices';
import exercisesOfTheDayReducer from '../containers/ExercisesOfTheDay/slices';
import exerciseOfTheDayReducer from '../containers/ExercisePage/slices';
import lessonReducer from '../containers/LessonPage/slices';
import adminReducers from '../containers/Admins/slices';
import usersReducer from '../containers/Users/slices';
import userReducer from '../containers/UserPage/slices';
import trainersReducer from '../containers/Trainers/slices';
import trainerReducer from '../containers/TrainerPage/slices';
import achievementsReducer from '../containers/Achievements/slices';
import achievementReducer from '../containers/AchievementsPage/slices';
import challengesReducer from '../containers/Challenges/slices';
import challengeReducer from '../containers/ChallengesPage/slices';
import challengeDayReducer from '../containers/ChallengeDaysPage/slices';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    courses: coursesReducer,
    course: courseReducer,
    exercisesOfTheDay: exercisesOfTheDayReducer,
    exerciseOfTheDay: exerciseOfTheDayReducer,
    lesson: lessonReducer,
    admins: adminReducers,
    users: usersReducer,
    user: userReducer,
    trainers: trainersReducer,
    trainer: trainerReducer,
    achievements: achievementsReducer,
    achievement: achievementReducer,
    challenges: challengesReducer,
    challenge: challengeReducer,
    challengeDay: challengeDayReducer,
    // add here container reducers
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
