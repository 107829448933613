import { Box, Grid } from '@mui/material';
import React, { FC, memo } from 'react';
import { ILesson } from '../../Courses/types';
import LessonCard from './LessonCard';

interface IProfileListComponentProps {
  lessons?: ILesson[];
}

const LessonList: FC<IProfileListComponentProps> = memo(({ lessons }) => (
  <div>
    <h2>Course lessons:</h2>
    <Box marginTop={5} marginBottom={5}>
      <Grid
        container
        justifyContent="left"
        alignItems="left"
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {lessons?.length &&
          lessons?.map((lesson) => (
            <Grid item xs={12} sm={6} md={4} key={lesson.id}>
              <LessonCard lesson={lesson} />
            </Grid>
          ))}
      </Grid>
    </Box>
  </div>
));

export default LessonList;
