import local from './local';
import prod from './prod';
import dev from './dev';
import stage from './stage';

type Environment = 'prod' | 'local' | 'dev' | 'stage';

const config: any = {
  local,
  prod,
  dev,
  stage,
};

const environment: Environment =
  (process.env.REACT_APP_ENV as Environment) || 'local';

export default config[environment];
