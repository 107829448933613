import { Box, Button, Grid } from '@mui/material';
import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../core/router/appRoutes';
import ExerciseOfTheDayCard from '../components/Card';
import { IExerciseOfTheDayItem } from '../types';
import { useAppSelector } from '../../../hooks';
import { getExercisesOfTheDayLoadMoreSelector } from '../selector';

export interface IExercisesOfTheDayPageProp {
  exercises: IExerciseOfTheDayItem[];
  exercisesSection: string;
  onDraftClick: () => void;
  onClickLoadMore: () => void;
}

const ExercisesOfTheDayPage: FC<IExercisesOfTheDayPageProp> = memo(
  ({ exercises, exercisesSection, onDraftClick, onClickLoadMore }) => {
    const loadMoreStatus = useAppSelector(getExercisesOfTheDayLoadMoreSelector);

    return (
      <div>
        <h1>Exercises of the day:</h1>
        <div>
          <Button
            size="medium"
            variant="contained"
            style={{ marginRight: '10' }}
            onClick={() => onDraftClick()}
          >
            {exercisesSection}
          </Button>{' '}
          <Link to={`${APP_ROUTES.EXERCISES_OF_THE_DAY}/create`}>
            <Button size="medium" variant="contained">
              Create exercise of the day
            </Button>
          </Link>
        </div>
        <Box marginTop={5} marginBottom={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="left"
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {exercises.length &&
              exercises.map((exercise) => (
                <Grid item xs={12} sm={6} md={4} key={exercise.id}>
                  <ExerciseOfTheDayCard exercise={exercise} />
                </Grid>
              ))}
          </Grid>
        </Box>
        {loadMoreStatus ? (
          <Button
            size="medium"
            variant="contained"
            onClick={() => {
              onClickLoadMore();
            }}
          >
            Load more
          </Button>
        ) : (
          <span>All exercises loaded</span>
        )}
      </div>
    );
  },
);

export default ExercisesOfTheDayPage;
