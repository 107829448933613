import React, { Skeleton } from '@mui/material';
import { memo } from 'react';

const PagePreLoader = memo(() => (
  <>
    <Skeleton variant="rectangular" height="64px" width="100%" />
    <Skeleton variant="rectangular" height="calc(100vh - 64px)" width={240} />
  </>
));

export default PagePreLoader;
