import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../../utils/constants';
import { deleteAchievement, getAllAchievements } from '../service';
import { IAchievementsState, IGetAllAchievements } from '../types';
import { IStatusDelete } from '../../Courses/service';

const initialState: IAchievementsState = {
  isLoading: false,
  errors: [],
  count: 0,
  achievements: [],
  page: 1,
  loadMore: true,
};

export const achievementsSlice = createSlice({
  name: 'achievements',
  initialState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllAchievements.fulfilled,
      (state, { payload }: PayloadAction<IGetAllAchievements>) => {
        state.isLoading = false;
        state.count = payload.count;
        state.achievements =
          state.page > 1
            ? [...state.achievements, ...payload.achievements]
            : payload.achievements;
        state.loadMore = state.achievements.length !== payload.count;
      },
    );
    builder.addCase(getAllAchievements.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllAchievements.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAchievement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deleteAchievement.fulfilled,
      (state, { payload }: PayloadAction<IStatusDelete>) => {
        toast.success(ToastMessage.DELETE_ACHIEVEMENT_SUCCESS);
        state.isLoading = false;
        state.achievements = state.achievements.filter(
          (achievement) => achievement.id !== payload.id,
        );
      },
    );
    builder.addCase(deleteAchievement.rejected, (state) => {
      toast.warning(ToastMessage.DELETE_ACHIEVEMENT_ERROR);
      state.isLoading = false;
    });
  },
});

export const { setPage } = achievementsSlice.actions;
export default achievementsSlice.reducer;
