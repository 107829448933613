import React, { FC, memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../../containers/Login';
import { APP_ROUTES } from './appRoutes';
import { RegisteredAdminGuard, UnregisteredAdminGuard } from '../guards';
import Home from '../../containers/Home';
import BaseLayout from '../layouts/BaseLayout';
import Courses from '../../containers/Courses';
import Challenges from '../../containers/Challenges';
import CoursePage from '../../containers/CoursePage';
import LessonPage from '../../containers/LessonPage';
import Users from '../../containers/Users';
import UserPage from '../../containers/UserPage';
import { Role } from '../../types';
import Admins from '../../containers/Admins';
import Trainers from '../../containers/Trainers';
import TrainerPage from '../../containers/TrainerPage';
import Achievements from '../../containers/Achievements';
import AchievementsPage from '../../containers/AchievementsPage';
import ChallengesPage from '../../containers/ChallengesPage';
import ChallengeDaysPage from '../../containers/ChallengeDaysPage';
import ExercisesOfTheDay from '../../containers/ExercisesOfTheDay';
import ExerciseOfTheDayPage from '../../containers/ExercisePage';

const Router: FC = memo(() => (
  <BaseLayout>
    <Routes>
      <Route
        path={APP_ROUTES.LOGIN}
        element={
          <UnregisteredAdminGuard>
            <Login />
          </UnregisteredAdminGuard>
        }
      />
      <Route
        path={APP_ROUTES.MAIN}
        element={
          <RegisteredAdminGuard>
            <Home />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={APP_ROUTES.COURSES}
        element={
          <RegisteredAdminGuard>
            <Courses isDraft={false} />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.COURSES}/drafts`}
        element={
          <RegisteredAdminGuard>
            <Courses isDraft />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.COURSES}/:id`}
        element={
          <RegisteredAdminGuard>
            <CoursePage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.COURSES}/create`}
        element={
          <RegisteredAdminGuard>
            <CoursePage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.ACHIEVEMENTS}/create`}
        element={
          <RegisteredAdminGuard>
            <AchievementsPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.ACHIEVEMENTS}/:id`}
        element={
          <RegisteredAdminGuard>
            <AchievementsPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.LESSONS}/:id`}
        element={
          <RegisteredAdminGuard>
            <LessonPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.COURSES}/:courseId${APP_ROUTES.LESSONS}/create`}
        element={
          <RegisteredAdminGuard>
            <LessonPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={APP_ROUTES.USERS}
        element={
          <RegisteredAdminGuard>
            <Users />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={APP_ROUTES.USER}
        element={
          <RegisteredAdminGuard>
            <UserPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={APP_ROUTES.ADMINS}
        element={
          <RegisteredAdminGuard role={Role.SUPER_ADMIN}>
            <Admins />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={APP_ROUTES.TRAINERS}
        element={
          <RegisteredAdminGuard>
            <Trainers />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.TRAINERS}/:id/edit`}
        element={
          <RegisteredAdminGuard>
            <TrainerPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.TRAINERS}/create`}
        element={
          <RegisteredAdminGuard>
            <TrainerPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={APP_ROUTES.ACHIEVEMENTS}
        element={
          <RegisteredAdminGuard>
            <Achievements />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={APP_ROUTES.CHALLENGES}
        element={
          <RegisteredAdminGuard>
            <Challenges active />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.CHALLENGES}/drafts`}
        element={
          <RegisteredAdminGuard>
            <Challenges active={false} />
          </RegisteredAdminGuard>
        }
      />

      <Route
        path={`${APP_ROUTES.CHALLENGES}/create`}
        element={
          <RegisteredAdminGuard>
            <ChallengesPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.CHALLENGES}/:id`}
        element={
          <RegisteredAdminGuard>
            <ChallengesPage details />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.CHALLENGES}/:id/tasks`}
        element={
          <RegisteredAdminGuard>
            <ChallengesPage details={false} />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.CHALLENGES}/:id/tasks/create`}
        element={
          <RegisteredAdminGuard>
            <ChallengeDaysPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.CHALLENGES}/:id/tasks/:taskId`}
        element={
          <RegisteredAdminGuard>
            <ChallengeDaysPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.EXERCISES_OF_THE_DAY}`}
        element={
          <RegisteredAdminGuard>
            <ExercisesOfTheDay isDraft={false} />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.EXERCISES_OF_THE_DAY}/drafts`}
        element={
          <RegisteredAdminGuard>
            <ExercisesOfTheDay isDraft />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.EXERCISES_OF_THE_DAY}/create`}
        element={
          <RegisteredAdminGuard>
            <ExerciseOfTheDayPage />
          </RegisteredAdminGuard>
        }
      />
      <Route
        path={`${APP_ROUTES.EXERCISES_OF_THE_DAY}/:id`}
        element={
          <RegisteredAdminGuard>
            <ExerciseOfTheDayPage />
          </RegisteredAdminGuard>
        }
      />
    </Routes>
  </BaseLayout>
));

export default Router;
