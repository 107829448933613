import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ICourseItem, ICoursesResponse } from '../types';
import { deleteCourse, getCourses, IStatusDelete } from '../service';
import { pageSize, ToastMessage } from '../../../utils/constants';
import { IErrorResponse } from '../../../types';

export interface ICoursesState {
  courses: ICourseItem[];
  count: number;
  page: number;
  pageSize: number;
  loadMore: boolean;
  isLoading: boolean;
  errors: string[];
}

const initialState: ICoursesState = {
  courses: [],
  count: 0,
  page: 1,
  pageSize,
  loadMore: true,
  isLoading: false,
  errors: [],
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCourses.fulfilled,
      (state, { payload }: PayloadAction<ICoursesResponse>) => {
        state.courses =
          payload.page > 1
            ? [...state.courses, ...payload.courses]
            : payload.courses;
        state.loadMore = state.courses.length !== payload.count;
        state.isLoading = false;
      },
    );
    builder.addCase(getCourses.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCourses.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deleteCourse.fulfilled,
      (state, { payload }: PayloadAction<IStatusDelete>) => {
        if (payload.id) {
          state.courses = state.courses.filter(
            (course) => course.id !== payload.id,
          );
          state.isLoading = false;
          toast.success(ToastMessage.DELETE_COURSE_SUCCESS);
        }
      },
    );
    builder.addCase(
      deleteCourse.rejected,
      (state, { payload }: PayloadAction<IErrorResponse | undefined>) => {
        state.isLoading = false;
        if (payload?.message) state.errors.push(ToastMessage.DELETE_TRAINER_ERROR);
      },
    );

    builder.addCase(deleteCourse.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export default coursesSlice.reducer;
