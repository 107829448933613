import { createAsyncThunk } from '@reduxjs/toolkit';
import { del, get, post, put } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import { ICourseItem } from '../../Courses/types';
import { ICourseForRequest, IUpdateCourse } from '../types';
import { uploadVideoToS3 } from '../../../utils/api';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

interface ICourseParams {
  id: string;
}

export interface IStatusDelete {
  id: string;
}

interface ICourseUpdateParams extends ICourseForRequest {
  id: string;
}

export interface ICourseCreateParams extends IUpdateCourse {
  id: string;
}

interface ICourseUpdatePreviewParams {
  id: string;
  file: File;
}

export interface ICourseMediaUpdateResponse {
  url: string;
}

export const getCourse = createAsyncThunk<
  ICourseItem,
  ICourseParams,
  ICommonChunkConfig
>('course/fetchCourse', async (params, { rejectWithValue }) => {
  try {
    const response = await get({ path: `${API_ROUTES.COURSES}/${params.id}` });
    return response as ICourseItem;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const createCourse = createAsyncThunk<
  ICourseCreateParams,
  ICourseForRequest,
  ICommonChunkConfig
>('course/createCourse', async (params, { rejectWithValue }) => {
  try {
    const level = params.levelRef || [];
    const preview = params.previewRef;
    const response = await post({
      path: `${API_ROUTES.COURSES}`,
      data: { ...params, level, previewOption: preview },
    });
    return response as ICourseCreateParams;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateCourse = createAsyncThunk<
  ICourseForRequest,
  ICourseUpdateParams,
  ICommonChunkConfig
>('course/updateCourse', async (params, { rejectWithValue }) => {
  try {
    const level = params.levelRef || [];
    const preview = params.previewRef;
    await put({
      path: `${API_ROUTES.COURSES}/${params.id}`,
      data: { ...params, level, previewOption: preview },
    });
    return {
      name: params.name,
      description: params.description,
      isDraft: params.isDraft,
      isNew: params.isNew,
      isPopular: params.isPopular,
      level: params.level,
      category: params.category,
      previewOption: params.previewOption,
      position: params.position,
      shortDescription: params.shortDescription,
    } as ICourseForRequest;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateCourseImage = createAsyncThunk<
  ICourseMediaUpdateResponse,
  ICourseUpdatePreviewParams,
  ICommonChunkConfig
>('course/updateCourseImage', async (params, { rejectWithValue }) => {
  try {
    const response = await post(
      {
        path: `${API_ROUTES.COURSES}/${params.id}/upload-course-image`,
        data: { file: params.file },
      },
      'multipart/form-data',
    );
    return response as ICourseMediaUpdateResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateCoursePreview = createAsyncThunk<
  ICourseMediaUpdateResponse,
  ICourseUpdatePreviewParams,
  ICommonChunkConfig
>('course/updateCoursePreview', async (params, { rejectWithValue }) => {
  try {
    const response = await post(
      {
        path: `${API_ROUTES.COURSES}/${params.id}/upload-course-preview`,
        data: { file: params.file },
      },
      'multipart/form-data',
    );
    return response as ICourseMediaUpdateResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateCourseVideo = createAsyncThunk<
  ICourseMediaUpdateResponse,
  ICourseUpdatePreviewParams,
  ICommonChunkConfig
>('course/updateCourseVideo', async (params, { rejectWithValue }) => {
  try {
    const key = await uploadVideoToS3(params.file);

    const response = await post({
      path: `${API_ROUTES.COURSES}/${params.id}/add-video-preview`,
      data: { key },
    });
    return response as ICourseMediaUpdateResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const deleteLesson = createAsyncThunk<
  IStatusDelete,
  IStatusDelete,
  ICommonChunkConfig
>('course/deleteLesson', async (params, { rejectWithValue }) => {
  try {
    await del({
      path: `${API_ROUTES.COURSES}${API_ROUTES.LESSONS}/${params.id}`,
    });
    return { id: params.id } as IStatusDelete;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
