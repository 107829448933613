import React, { memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ChallengesPage from './components/ChallengesPage';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getChallengesSelector,
  getChallengesLoadingSelector,
  getChallengesPageSelector,
} from './selector';
import { pageSize } from '../../utils/constants';
import { changePublishStatus, deleteChallenge, getChallenges } from './service';
import ChallengeTabs from './components/Tabs';
import { APP_ROUTES } from '../../core/router/appRoutes';

interface IChallengesProps {
  active: boolean;
}

const Challenges = memo(({ active }: IChallengesProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const challenges = useAppSelector(getChallengesSelector);
  const isLoading = useAppSelector(getChallengesLoadingSelector);
  const page = useAppSelector(getChallengesPageSelector);
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const getAllChallenges = (isDraft: boolean) => {
    dispatch(getChallenges({ page, pageSize, isDraft }));
  };

  useEffect(() => {
    getAllChallenges(!isActive);
  }, [page, isActive]);

  const changeStatus = useCallback(
    (id: string, isDraft: boolean) => {
      dispatch(changePublishStatus({ id, isDraft })).then(() => getAllChallenges(!isActive));
    },
    [changePublishStatus, isActive],
  );

  const removeChalenge = useCallback(
    (id: string) => {
      dispatch(deleteChallenge({ id }));
    },
    [deleteChallenge],
  );

  const onClickCreate = useCallback(
    () => navigate(`${APP_ROUTES.CHALLENGES}/create`, {
      state: { from: location },
    }),
    [location],
  );

  return (
    <>
      <h1>Challenges:</h1>
      <ChallengeTabs isActive={isActive} onClick={onClickCreate} />
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <ChallengesPage
          challenges={challenges}
          isActive={isActive}
          changeStatus={changeStatus}
          deleteChallenge={removeChalenge}
        />
      )}
    </>
  );
});

export default Challenges;
