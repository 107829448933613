import { toast } from 'react-toastify';
import { get } from '../core/axios';
import { API_ROUTES } from '../core/router/apiRoutes';
import { ToastMessage } from './constants';

export const uploadVideoToS3 = async (file: File) => {
  try {
    const { url, key } = await get<{ url: string; key: string }>({
      path: API_ROUTES.GET_S3_VIDEO_UPLOAD_URL,
      params: { contentType: file.type },
    });

    await fetch(url, {
      method: 'PUT',
      body: file,
    });

    return key;
  } catch (error) {
    toast.error(ToastMessage.UPLOAD_VIDEO_TO_S3_ERROR);
    throw error;
  }
};
