import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../Form';
import {
  getDaysLoadingSelector,
  getIsImageLoadingSelector,
  getIsVideoLoadingSelector,
} from '../../selector';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import BackLink from '../../../../components/BackLink';
import { IDayForm } from '../../types';
import { ToastMessage } from '../../../../utils/constants';
import { createChallengeDay, uploadImage, uploadVideo } from '../../service';
import { getChallengeSelector } from '../../../ChallengesPage/selector';
import { IChallengeDay } from '../../../Challenges/types';

const CreateDay: FC = () => {
  const isLoading = useAppSelector(getDaysLoadingSelector);
  const isImageLoading = useAppSelector(getIsImageLoadingSelector);
  const isVideoLoading = useAppSelector(getIsVideoLoadingSelector);
  const loading = isLoading || isImageLoading || isVideoLoading;
  const challenge = useAppSelector(getChallengeSelector);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const position = challenge ? challenge.challengeDays.length + 1 : '';

  const goBack = () => navigate(-1);

  const onSubmit = ({
    name,
    description,
    image,
    video,
    duration,
  }: IDayForm) => {
    const isFieldsFull =
      name &&
      duration &&
      description &&
      image &&
      image.length &&
      video &&
      video.length;
    if (isFieldsFull && id && position) {
      dispatch(
        createChallengeDay({
          name,
          description,
          id,
          position,
          date: new Date(),
        }),
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          const { id: dayId } = payload as IChallengeDay;
          if (dayId) {
            const img = image[0];
            const vid = video[0];
            dispatch(
              uploadImage({
                id: dayId,
                file: img,
              }),
            );
            dispatch(
              uploadVideo({
                id: dayId,
                file: vid,
                duration,
              }),
            ).then(() => {
              goBack();
            });
          }
        }
      });
    } else {
      toast.warning(ToastMessage.CHECK_ALL_FIELDS);
    }
  };

  return (
    <div>
      <BackLink text="Back to Tasks of the day" onClick={goBack} />
      <h1>{`Create Day ${position}`}</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Form onSubmit={onSubmit} position={position} />
      )}
    </div>
  );
};

export default CreateDay;
