import React, { FC, memo } from 'react';
import { ILoginForm } from './LoginForm';
import { useAppDispatch } from '../../hooks';
import { getCurrentUser, login } from './services';
import LoginWrapper from './LoginWrapper';

const Login: FC = memo(() => {
  const dispatch = useAppDispatch();
  const onSubmit = (values: ILoginForm) => {
    dispatch(login(values)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(getCurrentUser());
      }
    });
  };

  return <LoginWrapper onSubmit={onSubmit} />;
});

export default Login;
