import { Card, Typography, styled as muiStyled, colors } from '@mui/material';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NoChallenges = styled.div`
  width: 100%;
  text-align: center;
  border-radius: 12px;
  padding: 24px;
  background-color: ${colors.common.white};
`;

export const GrayText = styled(Typography)`
  color: ${colors.grey[600]};
`;

export const StyledCard = muiStyled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  maxWidth: 600,
  boxShadow: 'none',
  borderRadius: '12px',
}));
