import React, { memo } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledChip, TabsContainer, Tabs } from '../../styles';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

interface TabsProps {
  isActive: boolean;
  onClick: () => void;
}

const ChallengeTabs = memo(({ isActive, onClick }: TabsProps) => (
  <TabsContainer>
    <Tabs>
      <Link to={APP_ROUTES.CHALLENGES}>
        <StyledChip
          label="Active Challenges"
          {...(isActive
            ? {
              color: 'primary',
            }
            : {
              variant: 'outlined',
            })}
        />
      </Link>
      <Link to={`${APP_ROUTES.CHALLENGES}/drafts`}>
        <StyledChip
          label="Drafts"
          {...(isActive
            ? {
              variant: 'outlined',
            }
            : {
              color: 'primary',
            })}
        />
      </Link>
    </Tabs>
    <Button variant="contained" onClick={onClick}>
      + CREATE NEW CHALLENGE
    </Button>
  </TabsContainer>
));

export default ChallengeTabs;
