import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../../utils/constants';
import {
  createExercise,
  getExercise,
  updateExercise,
  updatePreviewImage,
  updateAffirmationImage,
  updateExerciseVideo,
  updateVideoDurationByVideoId,
} from '../service';
import { IExerciseOfTheDayItem } from '../../ExercisesOfTheDay/types';

export interface IExerciseOfTheDayState {
  exercise: IExerciseOfTheDayItem | null;
  isLoading: boolean;
  errors: string[];
}

const initialState: IExerciseOfTheDayState = {
  exercise: null,
  isLoading: false,
  errors: [],
};

export const exerciseSlice = createSlice({
  name: 'exercise',
  initialState,
  reducers: {
    setExercise(state, { payload }: PayloadAction<IExerciseOfTheDayItem>) {
      state.exercise = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getExercise.fulfilled,
      (state, { payload }: PayloadAction<IExerciseOfTheDayItem>) => {
        state.exercise = payload;
        state.isLoading = false;
        if (state.errors.length) {
          state.errors = [];
        }
      },
    );
    builder.addCase(getExercise.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.GET_COURSE_ERROR);
    });
    builder.addCase(getExercise.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(createExercise.fulfilled, (state) => {
      toast.success(ToastMessage.CREATE_EXERCISE_SUCCESS);
      state.isLoading = false;
      if (state.errors.length) {
        state.errors = [];
      }
    });
    builder.addCase(createExercise.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.CREATE_EXERCISE_ERROR);
    });
    builder.addCase(createExercise.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateExercise.fulfilled, (state, { payload }) => {
      if (state.exercise) {
        if (payload.title) {
          state.exercise.title = payload.title;
        }
        if (payload.description) {
          state.exercise.description = payload.description;
        }
        if (payload.isDraft !== undefined) {
          state.exercise.isDraft = payload.isDraft;
        }
        if (payload.showTitle !== undefined) {
          state.exercise.showTitle = payload.showTitle;
        }
        if (payload.isDraft !== undefined) {
          state.exercise.isDraft = payload.isDraft;
        }

        if (payload.position) {
          state.exercise.position = payload.position;
        }
        toast.success(ToastMessage.UPDATE_EXERCISE_SUCCESS);
        state.isLoading = false;
        if (state.errors.length) {
          state.errors = [];
        }
      }
    });
    builder.addCase(updateExercise.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_EXERCISE_ERROR);
    });
    builder.addCase(updateExercise.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updatePreviewImage.fulfilled, (state, { payload }) => {
      if (payload.url) {
        state.isLoading = false;
        state.errors = [];
        toast.success(ToastMessage.UPDATE_EXERCISE_IMAGE_SUCCESS);
        if (state.errors.length) {
          state.errors = [];
        }
      }
    });
    builder.addCase(updatePreviewImage.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_EXERCISE_IMAGE_ERROR);
    });
    builder.addCase(updatePreviewImage.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateAffirmationImage.fulfilled, (state, { payload }) => {
      if (payload.url) {
        state.isLoading = false;
        state.errors = [];
        toast.success(ToastMessage.UPDATE_EXERCISE_AFFIRMATION_SUCCESS);
        if (state.errors.length) {
          state.errors = [];
        }
      }
    });
    builder.addCase(updateAffirmationImage.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_EXERCISE_AFFIRMATION_ERROR);
    });
    builder.addCase(updateAffirmationImage.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateExerciseVideo.fulfilled, (state, { payload }) => {
      if (payload.url) {
        state.isLoading = false;
        if (state.exercise?.video) {
          state.exercise.video.url = payload.url;
          state.exercise.video.id = payload.id;
        }
        if (state.errors.length) {
          state.errors = [];
        }
        toast.success(ToastMessage.UPDATE_EXERCISE_PREVIEW_VIDEO_SUCCESS);
      }
    });
    builder.addCase(updateExerciseVideo.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_EXERCISE_PREVIEW_VIDEO_ERROR);
    });
    builder.addCase(updateExerciseVideo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateVideoDurationByVideoId.fulfilled, (state) => {
      state.isLoading = false;
      toast.success(
        ToastMessage.UPDATE_EXERCISE_PREVIEW_VIDEO_DURATION_SUCCESS,
      );
    });
    builder.addCase(updateVideoDurationByVideoId.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(
        ToastMessage.UPDATE_EXERCISE_PREVIEW_VIDEO_DURATION_ERROR,
      );
    });
    builder.addCase(updateVideoDurationByVideoId.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const { setExercise } = exerciseSlice.actions;

export default exerciseSlice.reducer;
