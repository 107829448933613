export const APP_ROUTES = {
  MAIN: '/',
  REGISTER: '/register',
  LOGIN: '/login',
  ABOUT_US: '/about-us',
  ADMINS: '/admins',
  USERS: '/users',
  USER: '/users/:id',
  LICENSE: '/license',
  COURSES: '/courses',
  LESSONS: '/lessons',
  TRAINERS: '/trainers',
  ACHIEVEMENTS: '/achievements',
  CHALLENGES: '/challenges',
  EXERCISES_OF_THE_DAY: '/exercises-of-the-day',
};
