import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  colors,
} from '@mui/material';

interface MenuItemProps {
  route: string;
  text: string;
  icon: JSX.Element;
  isSelected: boolean;
}

const MenuItem = ({ route, text, icon, isSelected }: MenuItemProps) => {
  const navigate = useNavigate();
  return (
    <ListItemButton onClick={() => navigate(route)} selected={isSelected}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={text}
        sx={isSelected ? { color: colors.blue[700] } : {}}
      />
    </ListItemButton>
  );
};

export default MenuItem;
