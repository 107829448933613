import {
  Typography,
  styled as muiStyled,
  Chip,
  Card,
  colors,
} from '@mui/material';
import styled from 'styled-components';

export const Title = styled.h1`
  margin: 0;
`;

export const TabsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NoChallenges = styled.div`
  width: 100%;
  text-align: center;
  border-radius: 12px;
  padding: 24px;
  margin-top: 32px;
  background-color: ${colors.common.white};
`;

export const GrayText = styled(Typography)`
  color: ${colors.grey[600]};
`;

export const Tabs = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledChip = muiStyled(Chip)(() => ({
  cursor: 'pointer',
  padding: '0px 12px',
}));

export const StyledCard = muiStyled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  maxWidth: 500,
  boxShadow: 'none',
  borderRadius: '12px',
  height: 'fit-content',
}));

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const LableContainer = styled(TitleContainer)`
  gap: 4px;
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
`;

export const DraftCardsContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const LableBox = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;
