import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, del } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import {
  IGetAllAchievements,
  IGetAllAchievementsRequest,
  IDeleteAchievementsRequest,
} from '../types';
import { IStatusDelete } from '../../Courses/service';

export interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export const getAllAchievements = createAsyncThunk<
IGetAllAchievements,
IGetAllAchievementsRequest,
ICommonChunkConfig
>('achievements/fetchAllAchievements', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.ACHIEVEMENTS,
      params: _data,
    });
    return response as IGetAllAchievements;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const deleteAchievement = createAsyncThunk<
IStatusDelete,
IDeleteAchievementsRequest,
ICommonChunkConfig
>('achievements/deleteAchievement', async (params, { rejectWithValue }) => {
  try {
    await del({ path: `${API_ROUTES.ACHIEVEMENTS}/${params.id}`, params: {} });
    return { id: params.id } as IStatusDelete;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
