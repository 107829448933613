import { createAsyncThunk } from '@reduxjs/toolkit';
import { patch, post, get } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import {
  ICreateAchievementsRequest,
  IGetAchievementsRequest,
  IUpdateAchievementsRequest,
  IUploadFileRequest,
  IUploadFileResponse,
} from '../types';
import { IAchievement } from '../../Achievements/types';

export interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export const createAchievement = createAsyncThunk<
IAchievement,
ICreateAchievementsRequest,
ICommonChunkConfig
>('achievement/createAchievement', async (data, { rejectWithValue }) => {
  try {
    const response = await post({ path: API_ROUTES.ACHIEVEMENTS, data });
    return response as IAchievement;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateAchievement = createAsyncThunk<
ICreateAchievementsRequest,
IUpdateAchievementsRequest,
ICommonChunkConfig
>('achievement/updateAchievement', async (data, { rejectWithValue }) => {
  try {
    const { id, ...params } = data;
    await patch({ path: `${API_ROUTES.ACHIEVEMENTS}/${id}`, data: params });
    return {
      name: params.name,
      description: params.description,
    };
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const getAchievement = createAsyncThunk<
IAchievement,
IGetAchievementsRequest,
ICommonChunkConfig
>('achievement/getAchievement', async (params, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.ACHIEVEMENTS}/${params.id}`,
    });
    return response as IAchievement;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const uploadImage = createAsyncThunk<
IUploadFileResponse,
IUploadFileRequest,
ICommonChunkConfig
>('achievement/uploadImage', async (data, { rejectWithValue }) => {
  try {
    const { id, file } = data;
    const response = await post(
      {
        path: `${API_ROUTES.ACHIEVEMENTS}/${id}/upload-achievement-image`,
        data: { file },
      },
      'multipart/form-data',
    );
    return response as IUploadFileResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
