import { RootState } from '../../../store';

export const getChallengeSelector = (state: RootState) =>
  state.challenge.challenge;
export const getChallengesLoadingSelector = (state: RootState) =>
  state.challenge.isLoading;
export const getChallengeUrlSelector = (state: RootState) =>
  state.challenge.url;
export const getPageSelector = (state: RootState) => state.challenge.page;
export const getChallengesLoadMoreSelector = (state: RootState) =>
  state.challenge.loadMoreAchievements;
export const getAchievementsFormSelector = (state: RootState) =>
  state.challenge.achievementsForm;
export const getAchievementSelector = (state: RootState) =>
  state.challenge.achievements;
