import React, { JSX, memo, useEffect } from 'react';
import Cookies from 'js-cookie';
import { ContentContainer } from './styles';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  getAccessTokenSelector,
  getCurrentUserSelector,
} from '../../selectors';
import { getCurrentUser, logout } from '../../../containers/Login/services';
import { COOKIE_ACCESS_TOKEN_NAME } from '../../../utils/constants';
import RegisteredLayout from '../RegisteredLayout';
import { setAccessToken } from '../../../containers/Login/slices';

interface IBaseLayoutProps {
  children: JSX.Element;
}

const BaseLayout = memo<IBaseLayoutProps>(({ children }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUserSelector);
  const accessToken = useAppSelector(getAccessTokenSelector);

  const onLogoutClickHandler = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(setAccessToken(Cookies.get(COOKIE_ACCESS_TOKEN_NAME) || ''));
  }, []);

  useEffect(() => {
    if (!currentUser && accessToken) {
      dispatch(getCurrentUser());
    }
  }, [currentUser, accessToken, dispatch]);

  return (
    <ContentContainer>
      {!currentUser ? (
        children
      ) : (
        <RegisteredLayout
          onLogoutClickHandler={onLogoutClickHandler}
          currentUser={currentUser}
        >
          {children}
        </RegisteredLayout>
      )}
    </ContentContainer>
  );
});

export default BaseLayout;
