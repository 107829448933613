import React, { memo, FC, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { IGuard } from './types';
import { useAppSelector } from '../../hooks';
import {
  getCurrentUserSelector,
  getLoadingUserSelector,
  isCurrentUserAdminSelector,
} from '../selectors';
import { APP_ROUTES } from '../router/appRoutes';
import { COOKIE_ACCESS_TOKEN_NAME } from '../../utils/constants';
import PagePreLoader from '../../components/PagePreLoader';
import PageLoader from '../../components/PageLoader';

export const RegisteredAdminGuard: FC<IGuard> = memo(
  ({ children, role }: IGuard) => {
    const [isRendered, rendered] = useState<boolean | null>(null);
    const currentUser = useAppSelector(getCurrentUserSelector);
    const isAdmin = useAppSelector(isCurrentUserAdminSelector);
    const isLoading = useAppSelector(getLoadingUserSelector);
    const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN_NAME);

    useEffect(() => {
      if (currentUser || !accessToken) {
        rendered(true);
      }
    }, [currentUser, accessToken]);

    if (isLoading || currentUser === undefined) {
      return <PagePreLoader />;
    }

    if (!isRendered) {
      return null;
    }

    return currentUser &&
      (role ? currentUser.roles.includes(role) : isAdmin) ? (
      children
    ) : (
      <Navigate to={APP_ROUTES.LOGIN} />
    );
  },
);

export const UnregisteredAdminGuard: FC<IGuard> = memo(
  ({ children }: IGuard) => {
    const currentUser = useAppSelector(getCurrentUserSelector);
    const isLoading = useAppSelector(getLoadingUserSelector);
    if (isLoading || currentUser === undefined) {
      return <PageLoader />;
    }

    return currentUser === null ? children : <Navigate to={APP_ROUTES.MAIN} />;
  },
);
