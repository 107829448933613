import React, { memo, useCallback } from 'react';
import { Grid } from '@mui/material';
import AddDayCard from './components/AddDayCard';
import DayCard from './components/DayCard';
import { ISingleChallenge } from '../Challenges/types';
import { StyledContainer } from './styled';
import { deleteChallengeDay } from '../ChallengesPage/service';
import { useAppDispatch } from '../../hooks';

interface IChallengeDaysPageProps {
  challenge: ISingleChallenge | null;
}

const ChallengeDaysPage = memo(({ challenge }: IChallengeDaysPageProps) => {
  const dispatch = useAppDispatch();
  const daysLeft = challenge
    ? challenge.duration - challenge.challengeDays.length
    : 0;
  const challengeId = challenge ? challenge.id : '';

  const removeChallengeDay = useCallback(
    (id: string) => {
      dispatch(deleteChallengeDay({ id }));
    },
    [deleteChallengeDay],
  );

  return (
    <StyledContainer>
      {daysLeft > 0 && (
        <AddDayCard daysLeft={daysLeft} challengeId={challengeId} />
      )}
      {challenge && challenge.challengeDays.length > 0 ? (
        <Grid
          container
          justifyContent="left"
          alignItems="left"
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {challenge.challengeDays.map((day) => (
            <Grid item xs={12} sm={6} md={4} key={day.id}>
              <DayCard
                day={day}
                challengeId={challengeId}
                removeChallengeDay={removeChallengeDay}
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </StyledContainer>
  );
});

export default ChallengeDaysPage;
