import { Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { IChallengeDay } from '../../../Challenges/types';
import {
  StyledCard,
  LableContainer,
  LableBox,
  GrayText,
} from '../../../Challenges/styles';
import AlertDialod from '../../../../components/AlertDialog/alert-dialog';

interface ICardProps {
  day: IChallengeDay;
  challengeId: string;
  removeChallengeDay: (id: string) => void;
}

const DayCard = memo(({ day, challengeId, removeChallengeDay }: ICardProps) => {
  const { name, image, duration, position, id } = day;
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const handleOpenDelete = () => setIsOpenDelete(true);
  const handleCloseDelete = () => setIsOpenDelete(false);
  const handleSubmitDelete = () => {
    removeChallengeDay(id);
    handleCloseDelete();
  };

  return (
    <>
      <StyledCard>
        {image && image?.url && (
          <CardMedia
            component="img"
            height="240"
            image={image.url}
            alt={name}
          />
        )}
        <CardContent>
          <Typography variant="body1">{`DAY ${position}`}</Typography>
          <Typography variant="h5" component="div" mb={2}>
            {name}
          </Typography>
          <LableContainer>
            <LableBox>
              <GrayText variant="body2">Duration:</GrayText>
              <Typography variant="body2">{`${duration} min`}</Typography>
            </LableBox>
          </LableContainer>
        </CardContent>
        <CardActions>
          <Link to={`${APP_ROUTES.CHALLENGES}/${challengeId}/tasks/${day.id}`}>
            <Button size="medium">Edit</Button>
          </Link>
          <Button size="medium" color="error" onClick={handleOpenDelete}>
            Delete
          </Button>
        </CardActions>
      </StyledCard>
      {isOpenDelete && (
        <AlertDialod
          question="Delete this day?"
          description="Are you sure you want to delete this day?"
          buttonAgree="Delete"
          buttonDisagree="Cancel"
          openStatus={isOpenDelete}
          handleSubmit={handleSubmitDelete}
          handleClose={handleCloseDelete}
        />
      )}
    </>
  );
});

export default DayCard;
