import React, { memo } from 'react';
import Select from 'react-select';
import { ISelectOption } from '../../containers/CoursePage/types';

interface IScrollSelect {
  loadMore: boolean;
  page: number;
  setPage: (value: number) => void;
  options: ISelectOption[];
  handleOpen?: () => void;
  handleClose?: () => void;
  isMulti?: boolean;
}

const ScrollSelect = memo(
  ({
    loadMore,
    page,
    setPage,
    options,
    handleClose,
    handleOpen,
    isMulti,
    ...props
  }: IScrollSelect) => {
    const handleScroll = (e: WheelEvent | TouchEvent) => {
      if (e && loadMore) {
        setPage(page + 1);
      }
    };
    return (
      <Select
        {...props}
        className="basic-single"
        classNamePrefix="select"
        options={options}
        onMenuOpen={handleOpen}
        onMenuClose={handleClose}
        isMulti={isMulti}
        onMenuScrollToBottom={(e) => handleScroll(e)}
        isOptionDisabled={(option) => (option.disabled !== undefined ? option.disabled : false)}
      />
    );
  },
);

export default ScrollSelect;
