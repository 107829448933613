import React, { memo } from 'react';
import { Grid } from '@mui/material';
import { DraftCardsContainer } from '../../styles';
import ChallengeCard from '../Card';
import { IChallenge } from '../../../ChallengesPage/types';

interface IDraftPageProps {
  challenges: IChallenge[];
  deleteChallenge: (id: string) => void;
}

const DraftPage = memo(({ challenges, deleteChallenge }: IDraftPageProps) => (
  <DraftCardsContainer>
    <Grid
      container
      justifyContent="left"
      alignItems="left"
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      {challenges.map((challenge) => (
        <Grid item xs={12} sm={6} md={4} key={challenge.id}>
          <ChallengeCard
            challenge={challenge}
            key={challenge.id}
            deleteChallenge={deleteChallenge}
          />
        </Grid>
      ))}
    </Grid>
  </DraftCardsContainer>
));

export default DraftPage;
