import React, { FC, memo } from 'react';
import PageWrapper from '../Wrapper';
import ExerciseOfTheDayCreatePage from './Page/create';
import ExerciseOfTheDayUpdatePage from './Page/update';

const ExerciseOfTheDayPage: FC = memo(() => (
  <PageWrapper
    component={ExerciseOfTheDayUpdatePage}
    componentOther={ExerciseOfTheDayCreatePage}
  />
));

export default ExerciseOfTheDayPage;
