import React, { memo } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { IUserResponse } from '../../../types';

interface IUserFormProps {
  user: IUserResponse;
  onResetPassword: () => void;
  isLoading: boolean;
}

const UserForm = memo<IUserFormProps>(
  ({ user, onResetPassword, isLoading }) => {
    const { email, code } = user;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            value={email}
            disabled
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="given-name"
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            value={code}
            id="code"
            name="code"
            label="code"
            fullWidth
            autoComplete="family-name"
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
          />
        </Grid>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={onResetPassword}
          sx={{ mt: 3, ml: 1 }}
        >
          Reset Password
        </Button>
      </Grid>
    );
  },
);

export default UserForm;
