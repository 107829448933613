import React, { memo, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { TabsContainer, Tabs, StyledChip } from '../../../Challenges/styles';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { ISingleChallenge } from '../../../Challenges/types';
import AlertDialod from '../../../../components/AlertDialog/alert-dialog';

interface TabsProps {
  isActive: boolean;
  challenge: ISingleChallenge | null;
  publishChallenge: () => void;
}

const FormTabs = memo(
  ({ isActive, challenge, publishChallenge }: TabsProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const daysLeft =
      challenge && challenge?.challengeDays
        ? `(${challenge?.challengeDays.length} / ${challenge?.duration})`
        : '';
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const handleSubmit = () => {
      publishChallenge();
      handleClose();
    };
    return (
      <>
        <TabsContainer>
          <Tabs>
            <Link to={`${APP_ROUTES.CHALLENGES}/${challenge?.id}`}>
              <StyledChip
                label="Challenge details"
                {...(isActive
                  ? {
                    color: 'primary',
                  }
                  : {
                    variant: 'outlined',
                  })}
              />
            </Link>
            <Link to={`${APP_ROUTES.CHALLENGES}/${challenge?.id}/tasks`}>
              <StyledChip
                label={`Tasks of the day ${daysLeft}`}
                {...(isActive
                  ? {
                    variant: 'outlined',
                  }
                  : {
                    color: 'primary',
                  })}
              />
            </Link>
          </Tabs>
          <Tooltip
            title="For publication, add all days with tasks"
            placement="top"
          >
            <div>
              <Button
                variant="contained"
                disabled={
                  challenge
                    ? challenge?.challengeDays?.length < challenge?.duration
                    : true
                }
                onClick={handleOpen}
              >
                Publish challenge
              </Button>
            </div>
          </Tooltip>
        </TabsContainer>
        {isOpen && (
          <AlertDialod
            question="Publish this challenge? "
            description="You currently have an ongoing challenge in the app banner. Are you sure you want to publish a new one instead?"
            buttonAgree="Publish"
            buttonDisagree="Cancel"
            openStatus={isOpen}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
          />
        )}
      </>
    );
  },
);

export default FormTabs;
