import React, { memo } from 'react';
import { Avatar, TableCell, TableRow } from '@mui/material';
import Table from '../../../components/Table';
import RemoveAdmin from './RemoveAdmin';
import { IUserResponse } from '../../../types';

interface IAdminsTableProps {
  admins: IUserResponse[];
}

const AdminsTable = memo<IAdminsTableProps>(({ admins }) => {
  const head = [
    {
      value: 'ID',
    },
    {
      value: 'Email',
    },
    {
      value: 'Roles',
    },
    {
      value: 'Last Login',
    },
    {
      value: 'Avatar',
    },
    {
      value: 'Remove',
    },
  ];

  return (
    <Table
      size="medium"
      head={head}
      rows={admins.map((admin) => (
        <TableRow key={admin.id}>
          <TableCell>{admin.id}</TableCell>
          <TableCell>{admin.email}</TableCell>
          <TableCell>{admin.roles.join(', ')}</TableCell>
          <TableCell>
            {admin?.lastLogin ? new Date(admin.lastLogin).toISOString() : null}
          </TableCell>
          <TableCell>
            <Avatar src={`${admin.profile?.avatar?.url}`} />
          </TableCell>
          <TableCell>
            <RemoveAdmin admin={admin} />
          </TableCell>
        </TableRow>
      ))}
    />
  );
});

export default AdminsTable;
