import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, put } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import { COURSE_ID_NOT_FOUND } from '../../../utils/constants';
import { ILesson } from '../../Courses/types';
import { ILessonForRequest, IUpdateLesson } from '../types';
import { uploadVideoToS3 } from '../../../utils/api';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

interface ILessonParams {
  id: string;
}

interface ILessonUpdateParams extends ILessonForRequest {
  id: string;
}

export interface ICreateLesson extends IUpdateLesson {
  courseId: string;
}
export interface ICreateLessonParams extends IUpdateLesson {
  id: string;
}

interface ILessonUpdatePreviewParams {
  id: string;
  file: File;
  duration?: number;
}

export interface ILessonMediaUpdateResponse {
  url: string;
}

export const getLesson = createAsyncThunk<
  ILesson,
  ILessonParams,
  ICommonChunkConfig
>('lesson/fetchLesson', async (params, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.COURSES}${API_ROUTES.LESSONS}/${params.id}`,
    });
    return response as ILesson;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const createLesson = createAsyncThunk<
  ICreateLessonParams,
  ICreateLesson,
  ICommonChunkConfig
>('lesson/createLesson', async (params, { rejectWithValue }) => {
  try {
    if (!params.courseId) {
      throw Error(COURSE_ID_NOT_FOUND);
    }
    const preview = params.previewRef;
    const response = await post({
      path: `${API_ROUTES.COURSES}/${params.courseId}${API_ROUTES.LESSONS}`,
      data: { ...params, previewOption: preview },
    });
    return response as ICreateLessonParams;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateLesson = createAsyncThunk<
  ILessonForRequest,
  ILessonUpdateParams,
  ICommonChunkConfig
>('lesson/updateLesson', async (params, { rejectWithValue }) => {
  try {
    const preview = params.previewRef;
    await put({
      path: `${API_ROUTES.COURSES}${API_ROUTES.LESSONS}/${params.id}`,
      data: { ...params, previewOption: preview },
    });
    return {
      name: params.name,
      description: params.description,
      previewOption: params.previewOption,
      position: params.position,
      videoDuration: params.videoDuration,
    } as ILessonUpdateParams;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateLessonImage = createAsyncThunk<
  ILessonMediaUpdateResponse,
  ILessonUpdatePreviewParams,
  ICommonChunkConfig
>('lesson/updateLessonImage', async (params, { rejectWithValue }) => {
  try {
    const response = await post(
      {
        path: `${API_ROUTES.COURSES}${API_ROUTES.LESSONS}/${params.id}/upload-image`,
        data: { file: params.file },
      },
      'multipart/form-data',
    );
    return response as ILessonMediaUpdateResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateLessonPreviewImage = createAsyncThunk<
  ILessonMediaUpdateResponse,
  ILessonUpdatePreviewParams,
  ICommonChunkConfig
>('lesson/updateLessonPreviewImage', async (params, { rejectWithValue }) => {
  try {
    const response = await post(
      {
        path: `${API_ROUTES.COURSES}${API_ROUTES.LESSONS}/${params.id}/upload-preview`,
        data: { file: params.file },
      },
      'multipart/form-data',
    );
    return response as ILessonMediaUpdateResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateLessonPreviewVideo = createAsyncThunk<
  ILessonMediaUpdateResponse,
  ILessonUpdatePreviewParams,
  ICommonChunkConfig
>('lesson/updateLessonPreviewVideo', async (params, { rejectWithValue }) => {
  try {
    const response = await post(
      {
        path: `${API_ROUTES.COURSES}${API_ROUTES.LESSONS}/${params.id}/upload-video-preview`,
        data: { file: params.file },
      },
      'multipart/form-data',
    );
    return response as ILessonMediaUpdateResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateLessonVideo = createAsyncThunk<
  ILessonMediaUpdateResponse,
  ILessonUpdatePreviewParams,
  ICommonChunkConfig
>('lesson/updateLessonVideo', async (params, { rejectWithValue }) => {
  try {
    const key = await uploadVideoToS3(params.file);

    const response = await post({
      path: `${API_ROUTES.COURSES}${API_ROUTES.LESSONS}/${params.id}/add-video`,
      data: { key, duration: params.duration },
    });
    return response as ILessonMediaUpdateResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
