import styled from 'styled-components';

export const TrainerFormLayout = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 30px;
`;

export const TrainerFormField = styled.div`
  margin-bottom: 20px;
`;
