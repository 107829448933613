import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, TextField } from '@mui/material';
import { LoginText } from '../constants';

export interface ILoginForm {
  email: string;
  password: string;
}

interface ILoginFormComponentProps {
  onSubmit: (data: ILoginForm) => void;
}

const LoginForm = memo<ILoginFormComponentProps>(({ onSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { isDirty, isValid, errors },
  } = useForm<ILoginForm>();

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        {...register('email')}
        {...(errors.email
          ? { error: true, helperText: errors.email.message }
          : {})}
        label={LoginText.EMAIL}
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label={LoginText.PASSWORD}
        type="password"
        {...register('password')}
        {...(errors.password
          ? { error: true, helperText: errors.password.message }
          : {})}
        autoComplete="current-password"
      />
      <Button
        disabled={!isDirty || !isValid}
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {LoginText.SIGN_IN}
      </Button>
    </Box>
  );
});

export default LoginForm;
