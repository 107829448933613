import { ResponseType } from 'axios';
import axiosApiInstance from './interceptors';

interface IDeleteOptions {
  path: string;
  params?: { [key: string]: string };
  responseType?: ResponseType;
}

interface IAxiosResponse {
  [key: string]: any;
}

export default async function del<T>(options: IDeleteOptions): Promise<T> {
  const { path, params = {}, responseType = 'json' } = options;

  const res: IAxiosResponse = await axiosApiInstance.delete<IAxiosResponse>(
    path,
    {
      responseType,
      params,
    },
  );
  if (res.data.error) {
    throw res.data.error;
  }
  return res.data;
}
