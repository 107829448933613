import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, patch } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import {
  ICommonChunkConfig,
  IErrorResponse,
  IMediaUpdateRequest,
  IUpdateImageResponse,
  IVideo,
} from '../../../types';
import { IExerciseOfTheDayItem } from '../../ExercisesOfTheDay/types';
import { uploadVideoToS3 } from '../../../utils/api';

export interface CreateExerciseRequest
  extends Omit<
    IExerciseOfTheDayItem,
    'id' | 'video' | 'previewImage' | 'affirmationImage' | 'created' | 'updated'
  > {}

export const getExercise = createAsyncThunk<
  IExerciseOfTheDayItem,
  { id: string },
  ICommonChunkConfig
>('exercise/fetchExercise', async (params, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.EXERCISE_OF_THE_DAY}/${params.id}`,
    });
    return response as IExerciseOfTheDayItem;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const createExercise = createAsyncThunk<
  IExerciseOfTheDayItem,
  CreateExerciseRequest,
  ICommonChunkConfig
>('exercise/createExercise', async (data, { rejectWithValue }) => {
  try {
    const response = await post({
      path: `${API_ROUTES.EXERCISE_OF_THE_DAY}`,
      data,
    });
    return response as IExerciseOfTheDayItem;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateExercise = createAsyncThunk<
  Partial<CreateExerciseRequest>,
  { id: string; data: Partial<CreateExerciseRequest> },
  ICommonChunkConfig
>('exercise/updateExercise', async ({ id, data }, { rejectWithValue }) => {
  try {
    await patch({
      path: `${API_ROUTES.EXERCISE_OF_THE_DAY}/${id}`,
      data,
    });
    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updatePreviewImage = createAsyncThunk<
  IUpdateImageResponse,
  IMediaUpdateRequest,
  ICommonChunkConfig
>('exercise/updateExercisePreviewImage', async (data, { rejectWithValue }) => {
  try {
    const response = await post(
      {
        path: `${API_ROUTES.EXERCISE_OF_THE_DAY}/${data.id}/upload-preview-image`,
        data: { file: data.file },
      },
      'multipart/form-data',
    );
    return response as IUpdateImageResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateAffirmationImage = createAsyncThunk<
  IUpdateImageResponse,
  IMediaUpdateRequest,
  ICommonChunkConfig
>(
  'exercise/updateExerciseAffirmationImage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await post(
        {
          path: `${API_ROUTES.EXERCISE_OF_THE_DAY}/${data.id}/upload-affirmation-image`,
          data: { file: data.file },
        },
        'multipart/form-data',
      );
      return response as IUpdateImageResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const updateExerciseVideo = createAsyncThunk<
  Pick<IVideo, 'url' | 'id'>,
  IMediaUpdateRequest,
  ICommonChunkConfig
>('exercise/updateExerciseVideo', async (data, { rejectWithValue }) => {
  try {
    const key = await uploadVideoToS3(data.file);

    const response = await post({
      path: `${API_ROUTES.EXERCISE_OF_THE_DAY}/${data.id}/add-video-preview`,
      data: { key, duration: data.duration },
    });
    return response as Pick<IVideo, 'url' | 'id'>;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateVideoDurationByVideoId = createAsyncThunk<
  Pick<IVideo, 'duration' | 'id'>,
  Pick<IVideo, 'duration' | 'id'>,
  ICommonChunkConfig
>(
  'exercise/updateExerciseVideoDurationByVideoId',
  async (data, { rejectWithValue }) => {
    try {
      await patch({
        path: `${API_ROUTES.EXERCISE_OF_THE_DAY}/${data.id}/update-video-duration`,
        data: { duration: data.duration },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);
