import React, { memo } from 'react';
import { Avatar, Button, TableCell, TableRow } from '@mui/material';
import Table from '../../../components/Table';
import { IUserResponse } from '../../../types';

interface IUserTableProps {
  users: IUserResponse[];
  onClick: (item: IUserResponse) => void;
}

const UsersTable = memo<IUserTableProps>(({ users, onClick }) => {
  const head = [
    {
      value: 'ID',
    },
    {
      value: 'Email',
    },
    {
      value: 'Verified',
    },
    {
      value: 'Code',
    },
    {
      value: 'Roles',
    },
    {
      value: 'FirstName',
    },
    {
      value: 'Birthday',
    },
    {
      value: 'Last Login',
    },
    {
      value: 'Avatar',
    },
    {
      value: 'Action',
    },
  ];

  return (
    <Table
      size="medium"
      head={head}
      rows={users.map((user) => (
        <TableRow key={user.id}>
          <TableCell>{user.id}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user.isVerified ? 'Yes' : 'No'}</TableCell>
          <TableCell>{user.code}</TableCell>
          <TableCell>{user.roles.join(', ')}</TableCell>
          <TableCell>{user.profile?.firstName}</TableCell>
          <TableCell>
            {user?.profile?.birthday
              ? new Date(user.profile?.birthday).toDateString()
              : null}
          </TableCell>
          <TableCell>
            {user?.lastLogin ? new Date(user.lastLogin).toISOString() : null}
          </TableCell>
          <TableCell>
            <Avatar src={`${user.profile?.avatar?.url}`} />
          </TableCell>
          <TableCell align="right">
            <Button onClick={() => onClick(user)} variant="text">
              Edit
            </Button>
          </TableCell>
        </TableRow>
      ))}
    />
  );
});

export default UsersTable;
