import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IErrorResponse } from '../../../types';
import { ToastMessage } from '../../../utils/constants';
import { ICourseItem } from '../../Courses/types';
import {
  createCourse,
  deleteLesson,
  getCourse,
  ICourseMediaUpdateResponse,
  IStatusDelete,
  updateCourse,
  updateCourseImage,
  updateCoursePreview,
  updateCourseVideo,
} from '../service';
import { CourseLevel, ICourseForRequest, PreviewType } from '../types';

export interface ICourseState {
  course: ICourseItem | null;
  isLoading: boolean;
  errors: string[];
}

const initialState: ICourseState = {
  course: null,
  isLoading: false,
  errors: [],
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCourse.fulfilled,
      (state, { payload }: PayloadAction<ICourseItem>) => {
        state.course = payload;
        state.isLoading = false;
        if (state.errors.length) {
          state.errors = [];
        }
      },
    );
    builder.addCase(getCourse.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.GET_COURSE_ERROR);
    });
    builder.addCase(getCourse.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createCourse.fulfilled, (state) => {
      toast.success(ToastMessage.CREATE_COURSE_SUCCESS);
      state.isLoading = false;
      if (state.errors.length) {
        state.errors = [];
      }
    });
    builder.addCase(createCourse.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.CREATE_COURSE_ERROR);
    });
    builder.addCase(createCourse.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateCourse.fulfilled,
      (state, { payload }: PayloadAction<ICourseForRequest>) => {
        if (state.course) {
          if (payload.name) {
            state.course.name = payload.name;
          }
          if (payload.description) {
            state.course.description = payload.description;
          }
          if (payload.isDraft !== undefined) {
            state.course.isDraft = payload.isDraft;
          }
          if (payload.isNew !== undefined) {
            state.course.isNew = payload.isNew;
          }
          if (payload.isDraft !== undefined) {
            state.course.isDraft = payload.isDraft;
          }
          state.course.level = payload.level?.length
            ? payload.level.map((item) => item.value as CourseLevel)
            : [];

          state.course.previewOption = payload.previewOption
            ?.value as PreviewType;

          if (payload.position) {
            state.course.position = payload.position;
          }
          if (payload.shortDescription) {
            state.course.shortDescription = payload.shortDescription;
          }
          toast.success(ToastMessage.UPDATE_COURSE_SUCCESS);
          state.isLoading = false;
          if (state.errors.length) {
            state.errors = [];
          }
        }
      },
    );
    builder.addCase(updateCourse.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_COURSE_ERROR);
    });
    builder.addCase(updateCourse.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateCoursePreview.fulfilled,
      (state, { payload }: PayloadAction<ICourseMediaUpdateResponse>) => {
        if (payload.url) {
          state.isLoading = false;
          if (state.errors.length) { state.errors = []; }
          toast.success(ToastMessage.UPDATE_COURSE_PREVIEW_SUCCESS);
          if (state.errors.length) {
            state.errors = [];
          }
        }
      },
    );
    builder.addCase(updateCoursePreview.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_COURSE_PREVIEW_ERROR);
    });
    builder.addCase(updateCoursePreview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateCourseVideo.fulfilled,
      (state, { payload }: PayloadAction<ICourseMediaUpdateResponse>) => {
        if (payload.url) {
          state.isLoading = false;
          if (state.errors.length) {
            state.errors = [];
          }
          toast.success(ToastMessage.UPDATE_COURSE_PREVIEW_VIDEO_SUCCESS);
        }
      },
    );
    builder.addCase(updateCourseVideo.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_COURSE_PREVIEW_VIDEO_ERROR);
    });
    builder.addCase(updateCourseVideo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateCourseImage.fulfilled,
      (state, { payload }: PayloadAction<ICourseMediaUpdateResponse>) => {
        if (payload.url) {
          state.isLoading = false;
          if (state.errors.length) {
            state.errors = [];
          }
          toast.success(ToastMessage.UPDATE_COURSE_IMAGE_SUCCESS);
        }
      },
    );
    builder.addCase(updateCourseImage.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_COURSE_IMAGE_ERROR);
    });
    builder.addCase(updateCourseImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deleteLesson.fulfilled,
      (state, { payload }: PayloadAction<IStatusDelete>) => {
        if (payload.id) {
          if (state.course?.lessons !== undefined) {
            state.course.lessons = state.course?.lessons.filter(
              (lesson) => lesson.id !== payload.id,
            );
          }
          state.isLoading = false;
          toast.success(ToastMessage.DELETE_COURSE_SUCCESS);
        }
      },
    );
    builder.addCase(
      deleteLesson.rejected,
      (state, { payload }: PayloadAction<IErrorResponse | undefined>) => {
        state.isLoading = false;
        if (payload?.message) state.errors.push(ToastMessage.DELETE_TRAINER_ERROR);
      },
    );

    builder.addCase(deleteLesson.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export default courseSlice.reducer;
