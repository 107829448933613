import React, { FC, memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Paper, TextField, Button } from '@mui/material';
import { CourseFormField } from '../../CoursePage/styles';
import {
  IPreviewOption,
  MediaFuncEnum,
  PreviewType,
} from '../../CoursePage/types';
import { ILessonForm } from '../types';
import { TrainerFormField, TrainerFormLayout } from '../../TrainerPage/styles';
import { useAppSelector } from '../../../hooks';
import {
  getIsVideoUploadingLessonSelector,
  getLoadingLessonSelector,
} from '../selector';

interface ILessonFormComponentProps {
  onSubmit: (data: ILessonForm) => void;
  onChangeFile?: (
    e: FileList | null,
    nameFunc: MediaFuncEnum,
    duration?: number,
  ) => void;
  form?: ILessonForm;
  oldImage?: string;
  oldVideo?: string;
  type: string;
}

const LessonForm: FC<ILessonFormComponentProps> = memo(
  ({ onSubmit, onChangeFile, form, type, oldImage, oldVideo }) => {
    const {
      handleSubmit,
      register,
      control,
      getValues,
      formState: { isDirty, errors },
    } = useForm<ILessonForm>({
      values: {
        name: form?.name,
        description: form?.description,
        position: form?.position,
        previewOption: form?.previewOption,
        videoDuration: form?.videoDuration,
      },
    });
    const isLoading = useAppSelector(getLoadingLessonSelector);
    const isVideoUploading = useAppSelector(getIsVideoUploadingLessonSelector);

    const optionsPreview = useMemo<IPreviewOption[]>(
      () =>
        Object.values(PreviewType).map((level) => ({
          label: level,
          value: level,
        })),
      [form?.previewOption],
    );

    const statusField = () => (form ? { shrink: true } : {});

    return (
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          minWidth: 345,
          maxWidth: 600,
        }}
      >
        <TrainerFormLayout onSubmit={handleSubmit(onSubmit)}>
          <TrainerFormField>
            <TextField
              label="Name"
              fullWidth
              {...register('name')}
              InputLabelProps={statusField()}
            />
            <div>{errors.name && errors.name.message}</div>
          </TrainerFormField>
          <TrainerFormField>
            <TextField
              label="Description"
              fullWidth
              multiline
              {...register('description')}
              InputLabelProps={statusField()}
            />
            <div>{errors.description && errors.description.message}</div>
          </TrainerFormField>
          <TrainerFormField>
            <TextField
              label="Position"
              fullWidth
              type="number"
              {...register('position')}
              InputLabelProps={statusField()}
            />
            <div>{errors.position && errors.position.message}</div>
          </TrainerFormField>
          <CourseFormField>
            <label htmlFor="previewOption">Preview option:</label>
            <Controller
              control={control}
              name="previewOption"
              render={({ field }) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  {...field}
                  options={optionsPreview}
                />
              )}
            />
            <div>{errors.previewOption && errors.previewOption.message}</div>
          </CourseFormField>
          {type === 'update' && (
            <CourseFormField>
              {oldImage && (
                <img src={oldImage} alt="course-img" style={{ width: '30%' }} />
              )}
              <label htmlFor="image">Lesson image:</label>
              <TextField
                fullWidth
                type="file"
                name="image"
                inputProps={{ accept: 'image/jpeg, image/png' }}
                onChange={(e) => {
                  if (onChangeFile) {
                    onChangeFile(
                      (e.target as HTMLInputElement).files,
                      MediaFuncEnum.image,
                    );
                  }
                }}
              />
            </CourseFormField>
          )}
          {type === 'update' && (
            <TrainerFormField>
              <TextField
                label="Video duration"
                fullWidth
                type="number"
                {...register('videoDuration')}
                InputLabelProps={statusField()}
              />
              <div>{errors.videoDuration && errors.videoDuration.message}</div>
            </TrainerFormField>
          )}
          {type === 'update' && (
            <CourseFormField>
              {oldVideo && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  src={oldVideo}
                  style={{ width: '320', height: '240' }}
                  controls
                />
              )}
              <label htmlFor="video">Lesson video:</label>
              <TextField
                fullWidth
                type="file"
                name="video"
                inputProps={{ accept: '.mp4, .avi, .mpeg, .mov' }}
                onChange={(e) => {
                  if (onChangeFile) {
                    onChangeFile(
                      (e.target as HTMLInputElement).files,
                      MediaFuncEnum.video,
                      getValues('videoDuration'),
                    );
                  }
                }}
              />
            </CourseFormField>
          )}
          {form ? (
            <Button
              size="medium"
              variant="contained"
              aria-label="Update lesson"
              type="submit"
              title="Update lesson"
              disabled={isLoading || isVideoUploading}
            >
              {' '}
              Update lesson
            </Button>
          ) : (
            <Button
              size="medium"
              variant="contained"
              disabled={!isDirty || isLoading || isVideoUploading}
              aria-label="Create lesson"
              type="submit"
              title="Create lesson"
            >
              {' '}
              Create lesson
            </Button>
          )}
          {errors.root?.message}
        </TrainerFormLayout>
      </Paper>
    );
  },
);

export default LessonForm;
