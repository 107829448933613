import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useRef, useState } from 'react';
import CreateAdminForm from '../CreateAdminForm';
import { IAdminCreateRequest } from '../types';

interface ICreateAdminModalProps {
  open: boolean;
  onSubmit: (data: IAdminCreateRequest) => void;
  handleClose: () => void;
}

const CreateAdminModal: FC<ICreateAdminModalProps> = ({
  open,
  handleClose,
  onSubmit,
}) => {
  const formRef = useRef<any>();

  const [isFormValid, setFormValid] = useState<boolean>();

  const handleOnCreateClick = () => {
    if (formRef?.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create Admin</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To create a new admin User, please enter their email address here. We
          will create them and send password to there
        </DialogContentText>
        <CreateAdminForm
          setFormValid={setFormValid}
          ref={formRef}
          onSubmit={onSubmit}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={!isFormValid} onClick={handleOnCreateClick}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAdminModal;
