import { ISelectOptionItem } from '../../CoursePage/types';

export enum Level {
  ALL = 'all',
  BEGINNER = 'beginner',
  ADVANCE = 'advance',
}

export interface IChallengeImage {
  id: string;
  path: string;
  size: number | null;
  url: string;
}

export interface IChallenge {
  name: string;
  description: string;
  shortDescription: string;
  difficulty: number;
  level: Level[];
  startDate: Date;
  finishDate: Date;
  completionText: string;
  achievementId: string;
  isDraft: boolean;
  id: string;
  image: IChallengeImage;
  created: Date;
  updated: Date;
  avgDuration: number;
}

export interface IAchievement {
  image: IAchievementImage | null;
  id: string;
  name: string;
  description: string;
}

export interface IChallengeLevel extends ISelectOptionItem<Level, Level> {}

export interface IAchievementOption extends ISelectOptionItem {
  disabled?: boolean;
}

export interface IChallengeForm {
  name?: string;
  description?: string;
  shortDescription?: string;
  level?: IChallengeLevel[];
  startDate?: Date | null;
  finishDate?: Date | null;
  achievementId?: IAchievementOption;
  files?: FileList;
  completionText?: string;
  difficulty?: number;
}

export interface ICreateChallengeRequest {
  name: string;
  description: string;
  shortDescription: string;
  level: Level[];
  startDate: Date;
  finishDate: Date;
  achievementId: string;
  isDraft: boolean;
  completionText?: string;
  difficulty?: number;
}

export interface IAchievementImage {
  id: string;
  path: string;
  size: number | null;
  url: string;
}

export interface IGetAllAchievementsRequest {
  page: number;
  pageSize: number;
}

export interface IGetAchievementsRequest {
  id: string;
}

export interface IGetAllAchievements {
  achievements: IAchievement[];
  count: number;
  page: number;
}

export interface IUploadFileRequest {
  id: string;
  file: File;
}

export interface IUploadFileResponse {
  url: string;
}

export interface IGetChallengeRequest {
  id: string;
}

export interface IUpdateChallengeRequest {
  id: string;
  challenge: ICreateChallengeRequest;
}
