import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IExerciseOfTheDayItem, IExerciseOfTheDayResponse } from '../types';
import {
  deleteExercise,
  getExercisesOfTheDay,
  IStatusDelete,
} from '../service';
import { pageSize, ToastMessage } from '../../../utils/constants';
import { IErrorResponse } from '../../../types';

export interface IExerciseOfTheDayState {
  exercisesOfTheDay: IExerciseOfTheDayItem[];
  count: number;
  page: number;
  pageSize: number;
  loadMore: boolean;
  isLoading: boolean;
  errors: string[];
}

const initialState: IExerciseOfTheDayState = {
  exercisesOfTheDay: [],
  count: 0,
  page: 1,
  pageSize,
  loadMore: true,
  isLoading: false,
  errors: [],
};

export const exercisesOfTheDaySlice = createSlice({
  name: 'exercises',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getExercisesOfTheDay.fulfilled,
      (state, { payload }: PayloadAction<IExerciseOfTheDayResponse>) => {
        state.exercisesOfTheDay =
          payload.page > 1
            ? [...state.exercisesOfTheDay, ...payload.exercisesOfTheDay]
            : payload.exercisesOfTheDay;
        state.loadMore = state.exercisesOfTheDay.length !== payload.count;
        state.isLoading = false;
      },
    );
    builder.addCase(getExercisesOfTheDay.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getExercisesOfTheDay.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deleteExercise.fulfilled,
      (state, { payload }: PayloadAction<IStatusDelete>) => {
        if (payload.id) {
          state.exercisesOfTheDay = state.exercisesOfTheDay.filter(
            (exercise) => exercise.id !== payload.id,
          );
          state.isLoading = false;
          toast.success(ToastMessage.DELETE_EXERCISE_OF_THE_DAY_SUCCESS);
        }
      },
    );
    builder.addCase(
      deleteExercise.rejected,
      (state, { payload }: PayloadAction<IErrorResponse | undefined>) => {
        state.isLoading = false;
        if (payload?.message)
          state.errors.push(ToastMessage.DELETE_EXERCISE_OF_THE_DAY_ERROR);
      },
    );

    builder.addCase(deleteExercise.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export default exercisesOfTheDaySlice.reducer;
