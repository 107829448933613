import React, { FC, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { IUserResponse, Role } from '../../../types';
import { useAppDispatch } from '../../../hooks';
import { removeAdmin } from '../services';

interface IRemoveAdminProps {
  admin: IUserResponse;
}

const RemoveAdmin: FC<IRemoveAdminProps> = ({ admin }) => {
  const [isOpen, setOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const dispatch = useAppDispatch();

  const handleConfirm = async () => {
    setIsRemoving(true);
    setOpen(false);
    await dispatch(removeAdmin(admin));
    setIsRemoving(false);
  };

  return (
    <>
      <Button
        disabled={admin.roles.includes(Role.SUPER_ADMIN) || isRemoving}
        onClick={() => setOpen(true)}
      >
        {isRemoving ? <CircularProgress /> : 'Remove'}
      </Button>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>Remove Admin</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove admin {admin.email}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default RemoveAdmin;
