import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IErrorResponse } from '../../../types';
import { ToastMessage } from '../../../utils/constants';
import { deleteTrainer, getTrainers, IStatusDelete } from '../service';
import { ITrainer } from '../types';

export interface ITrainersState {
  trainers: ITrainer[];
  isLoading: boolean;
  errors: string[];
}

const initialState: ITrainersState = {
  trainers: [],
  isLoading: false,
  errors: [],
};

export const trainersSlice = createSlice({
  name: 'trainers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getTrainers.fulfilled,
      (state, { payload }: PayloadAction<ITrainer[]>) => {
        state.trainers = payload;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getTrainers.rejected,
      (state, { payload }: PayloadAction<IErrorResponse | undefined>) => {
        state.isLoading = false;
        if (payload?.message) state.errors.push(ToastMessage.GET_TRAINERS_ERROR);
      },
    );

    builder.addCase(getTrainers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deleteTrainer.fulfilled,
      (state, { payload }: PayloadAction<IStatusDelete>) => {
        if (payload.id) {
          state.trainers = state.trainers.filter(
            (trainer) => trainer.id !== payload.id,
          );
          state.isLoading = false;
          toast.success(ToastMessage.DELETE_TRAINER_SUCCESS);
        }
      },
    );
    builder.addCase(
      deleteTrainer.rejected,
      (state, { payload }: PayloadAction<IErrorResponse | undefined>) => {
        state.isLoading = false;
        if (payload?.message) state.errors.push(ToastMessage.DELETE_TRAINER_ERROR);
      },
    );

    builder.addCase(deleteTrainer.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export default trainersSlice.reducer;
