import React, { memo } from 'react';
import ActivePage from '../ActivePage';
import DraftPage from '../DraftPage';
import { IChallenge } from '../../../ChallengesPage/types';

interface IPageWrapperProps {
  isActive: boolean;
  challenges: IChallenge[];
  changeStatus: (id: string, isDraft: boolean) => void;
  deleteChallenge: (id: string) => void;
}

const PageWrapper = memo(
  ({
    isActive,
    challenges,
    changeStatus,
    deleteChallenge,
  }: IPageWrapperProps) => (isActive ? (
    <ActivePage challenges={challenges} changeStatus={changeStatus} />
  ) : (
    <DraftPage challenges={challenges} deleteChallenge={deleteChallenge} />
  )),
);

export default PageWrapper;
