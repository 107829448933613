import styled from 'styled-components';

export const CourseFormBlock = styled.form`
  display: flex;
  flex-direction: column;
  width: 520px;
  border: 1px solid black;
  border-radius: 20px;
  padding: 30px;
  margin-left: 30px;
`;

export const CourseFormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

export const LessonCardBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 520px;
  border: 1px solid black;
  border-radius: 20px;
  padding: 30px;
  margin-left: 30px;
  margin-top: 20px;
`;
