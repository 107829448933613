import React, { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../Form';
import {
  getChallengeDayImageSelector,
  getChallengeDaySelector,
  getChallengeDayVideoSelector,
  getDaysLoadingSelector,
  getIsImageLoadingSelector,
  getIsVideoLoadingSelector,
} from '../../selector';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import BackLink from '../../../../components/BackLink';
import {
  getChallengeDay,
  updateChallengeDay,
  uploadImage,
  uploadVideo,
} from '../../service';
import { IDayForm } from '../../types';
import { ToastMessage } from '../../../../utils/constants';
import { clearDay } from '../../slices';

const UpdateDay: FC = () => {
  const isLoading = useAppSelector(getDaysLoadingSelector);
  const isImageLoading = useAppSelector(getIsImageLoadingSelector);
  const isVideoLoading = useAppSelector(getIsVideoLoadingSelector);
  const loading = isLoading || isImageLoading || isVideoLoading;
  const challengeDay = useAppSelector(getChallengeDaySelector);
  const oldImage = useAppSelector(getChallengeDayImageSelector);
  const oldVideo = useAppSelector(getChallengeDayVideoSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { taskId } = useParams();

  useEffect(() => {
    if (taskId) {
      dispatch(getChallengeDay({ id: taskId }));
    }
    return () => {
      dispatch(clearDay());
    };
  }, []);

  const checkForm = ({
    description,
    name,
    video,
    image,
    duration,
  }: IDayForm): boolean => {
    const isFormFull = !!(description && name && duration);
    const isImageAttached = !!(
      (image && image.length) ||
      (challengeDay?.image && challengeDay?.image.url)
    );
    const isVideoAttached = !!(
      (video && video.length) ||
      (challengeDay?.video && challengeDay?.video.url)
    );
    return isFormFull && isVideoAttached && isImageAttached;
  };

  const onSubmit = (form: IDayForm) => {
    const { description, name, video, image, duration } = form;
    const isFormFull = checkForm(form);

    if (taskId && isFormFull) {
      dispatch(
        updateChallengeDay({
          id: taskId,
          description,
          name,
          videoDuration: duration && +duration,
        }),
      ).then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          dispatch(getChallengeDay({ id: taskId }));
        }
      });

      if (video && video.length) {
        const file = video[0];
        dispatch(
          uploadVideo({
            file,
            id: taskId,
            duration,
          }),
        );
      }
      if (image && image.length) {
        const file = image[0];
        dispatch(
          uploadImage({
            file,
            id: taskId,
          }),
        );
      }
    } else {
      toast.warning(ToastMessage.CHECK_ALL_FIELDS);
    }
  };
  const goBack = () => navigate(-1);

  return (
    <div>
      <BackLink text="Back to Tasks of the day" onClick={goBack} />
      <h1>{`Update Day ${challengeDay?.position || ''}`}</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Form
          onSubmit={onSubmit}
          form={{
            name: challengeDay?.name || '',
            description: challengeDay?.description || '',
            duration: challengeDay?.duration || 0,
          }}
          oldImage={oldImage}
          oldVideoPreview={oldVideo}
          position={challengeDay?.position || ''}
        />
      )}
    </div>
  );
};

export default UpdateDay;
