import React, { memo } from 'react';
import { Button, Fab, LinearProgress, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { IUserResponse } from '../../../types';
import AdminsTable from '../AdminsTable';

interface IUserWrapperProps {
  isLoading: boolean;
  admins: IUserResponse[];
  loadMore: boolean;
  onNextClick: () => void;
  onCreateClick: () => void;
}

const UsersWrapper = memo<IUserWrapperProps>(
  ({ isLoading, admins, loadMore, onNextClick, onCreateClick }) => (
    <>
      {isLoading && <LinearProgress />}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Users
      </Typography>
      <AdminsTable admins={admins} />
      <Fab
        sx={{
          position: 'absolute',
          top: 80,
          right: 16,
        }}
        color="primary"
        variant="extended"
        aria-label="add"
        onClick={onCreateClick}
      >
        <AddIcon /> Create Admin
      </Fab>
      <Button
        disabled={!loadMore}
        color="primary"
        variant="text"
        onClick={onNextClick}
        sx={{ mt: 3 }}
      >
        Load More
      </Button>
    </>
  ),
);

export default UsersWrapper;
