import React, { memo } from 'react';
import { Button, LinearProgress, Typography } from '@mui/material';
import { IUserResponse } from '../../../types';
import UserForm from '../UserForm';

interface IUserWrapperProps {
  user: IUserResponse;
  onBackClick: () => void;
  onResetPasswordClick: () => void;
  isLoading: boolean;
}

const UserPageWrapper = memo<IUserWrapperProps>(
  ({ isLoading, user, onResetPasswordClick, onBackClick }) => (
    <>
      {isLoading && <LinearProgress />}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        User
      </Typography>
      <Button
        color="primary"
        variant="text"
        onClick={onBackClick}
        sx={{ mt: 3, mb: 3 }}
      >
        Back to list
      </Button>
      <UserForm
        user={user}
        onResetPassword={onResetPasswordClick}
        isLoading={isLoading}
      />
    </>
  ),
);

export default UserPageWrapper;
