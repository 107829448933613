import styled from 'styled-components';
import { Paper, styled as muiStyled } from '@mui/material';

export const FormLayout = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 32px;
  gap: 24px;
`;

export const StyledPaper = muiStyled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  minWidth: 345,
  maxWidth: 600,
  borderRadius: '8px',
}));
