import { IErrorResponse } from '../types';

export const getErrorMessage = (payload: IErrorResponse | undefined) => {
  let errorMessage = '';
  const message = payload?.message;
  if (message) {
    if (typeof message === 'string') {
      errorMessage = message;
    } else {
      errorMessage = message.join(', ');
    }
  }
  return errorMessage;
};

export const setStorageValue = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const removeStorageValue = (key: string) => {
  localStorage.removeItem(key);
};

export const getStorageValue = (key: string) => localStorage.getItem(key) || '';

export const shortenString = (string: string) => (string.length > 180 ? `${string.slice(0, 180)}...` : string);

export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getMonthName = (date: Date) => date.toLocaleString('en', { month: 'long' });
