export const COOKIE_ACCESS_TOKEN_NAME = 'accessToken';
export const LOCAL_STORAGE_REFRESH_TOKEN_NAME = 'refreshToken';

export enum ToastMessage {
  UPDATE_COURSE_SUCCESS = 'Successful course update',
  UPDATE_COURSE_PREVIEW_SUCCESS = 'Successful course preview update',
  UPDATE_COURSE_PREVIEW_VIDEO_SUCCESS = 'Successful course preview video update',
  UPDATE_COURSE_IMAGE_SUCCESS = 'Successful course image update',
  CREATE_COURSE_SUCCESS = 'Course created successfully',
  GET_COURSE_ERROR = 'Failed to get course',
  UPDATE_COURSE_ERROR = 'Course update error',
  UPDATE_COURSE_PREVIEW_ERROR = 'Course preview update error',
  UPDATE_COURSE_PREVIEW_VIDEO_ERROR = 'Course preview video update error',
  UPDATE_COURSE_IMAGE_ERROR = 'Course image update error',
  CREATE_COURSE_ERROR = 'Course create error',
  GET_COURSES_ERROR = 'Failed to get courses',
  GET_LESSON_ERROR = 'Failed to get lesson',
  UPDATE_LESSON_ERROR = 'Lesson update error',
  UPDATE_LESSON_SUCCESS = 'Successful lesson update',
  UPDATE_LESSON_PREVIEW_SUCCESS = 'Successful lesson preview image update',
  UPDATE_LESSON_PREVIEW_VIDEO_SUCCESS = 'Successful lesson preview video update',
  UPDATE_LESSON_IMAGE_SUCCESS = 'Successful lesson image update',
  UPDATE_LESSON_VIDEO_SUCCESS = 'Successful lesson video update',
  UPDATE_LESSON_PREVIEW_ERROR = 'Lesson preview update error',
  UPDATE_LESSON_PREVIEW_VIDEO_ERROR = 'Lesson preview video update error',
  UPDATE_LESSON_IMAGE_ERROR = 'Lesson image update error',
  UPDATE_LESSON_VIDEO_ERROR = 'Lesson video update error',
  CREATE_LESSON_SUCCESS = 'Lesson created successfully',
  CREATE_LESSON_ERROR = 'Lesson create error',
  VIDEO_ERROR = 'Fill in the fields: duration for video and lesson video',
  GET_TRAINERS_ERROR = 'Failed to get trainers',
  DELETE_TRAINER_ERROR = 'Failed to remove trainer',
  DELETE_TRAINER_SUCCESS = 'Successful trainer delete',
  CREATE_TRAINER_SUCCESS = 'Trainer created successfully',
  CREATE_TRAINER_ERROR = 'Trainer create error',
  GET_TRAINER_ERROR = 'Failed to get trainer',
  UPDATE_TRAINER_SUCCESS = 'Successful trainer update',
  DELETE_COURSE_SUCCESS = 'Successful course delete',
  DELETE_COURSE_ERROR = 'Failed to remove course',
  CHECK_FORM_FIELDS = 'Required fields: name, description, level and short description',
  CHECK_FORM_FIELDS_LESSON = 'Required fields: name and description',
  CHECK_ALL_FIELDS = 'All fields required',
  DELETE_ACHIEVEMENT_SUCCESS = 'Achievement deleted successfully',
  DELETE_ACHIEVEMENT_ERROR = 'Error deleting achievement',
  DELETE_ADMIN_ERROR = 'Error deleting admin',
  CREATE_ACHIEVEMENT_ERROR = 'Error creating achievement',
  CREATE_ACHIEVEMENT_SUCCESS = 'Achievement created successfully',
  UPDATE_ACHIEVEMENT_ERROR = 'Error updating achievement',
  UPDATE_ACHIEVEMENT_SUCCESS = 'Achievement updated successfully',
  GET_ACHIEVEMENT_ERROR = 'Failed to get achievement',
  UPLOAD_ACHIEVEMENT_IMAGE_SUCCESS = 'Image was uploaded successfully',
  UPLOAD_ACHIEVEMENT_IMAGE_ERROR = 'Error uploading image',
  UPDATE_ACHIEVEMENT_IMAGE_SUCCESS = 'Image was updated successfully',
  UPDATE_ACHIEVEMENT_IMAGE_ERROR = 'Error updating image',
  CHALLENGE_UNPUBISHED_SUCCESS = 'Challenge unpublished successfully',
  CHALLENGE_PUBISHED_SUCCESS = 'Challenge published successfully',
  CHALLENGE_STATUS_ERROR = 'Error changing status of the challenge',
  CREATE_CHALLENGE_SUCCESS = 'Challenge created successfully',
  CREATE_CHALLENGE_ERROR = 'Challenge create error',
  GET_ACHIEVEMENTS_ERROR = 'Failed to get achievements',
  UPLOAD_CHALLENGE_IMAGE_SUCCESS = 'Image was uploaded successfully',
  UPLOAD_CHALLENGE_IMAGE_ERROR = 'Error uploading image',
  DELETE_CHALLENGE_SUCCESS = 'Challenge deleted successfully',
  DELETE_CHALLENGE_DAY_SUCCESS = 'Challenge day deleted successfully',
  DELETE_CHALLENGE_ERROR = 'Error deleting challenge',
  DELETE_CHALLENGE_DAY_ERROR = 'Error deleting challenge day',
  GET_CHALLENGE_ERROR = 'Failed to get challenge',
  UPDATE_CHALLENGE_ERROR = 'Error updating challenge',
  UPDATE_CHALLENGE_SUCCESS = 'Challenge updated successfully',
  UPDATE_CHALLENGE_DAY_ERROR = 'Error updating challenge day',
  UPDATE_CHALLENGE_DAY_SUCCESS = 'Challenge day updated successfully',
  GET_CHALLENGE_DAYS_ERROR = 'Failed to get challenge days',
  GET_CHALLENGE_DAY_ERROR = 'Failed to get challenge day',
  CREATE_CHALLENGE_DAY_SUCCESS = 'Challenge day created successfully',
  CREATE_CHALLENGE_DAY_ERROR = 'Challenge day create error',
  UPLOAD_VIDEO_SUCCESS = 'Video was uploaded successfully',
  UPLOAD_VIDEO_ERROR = 'Error uploading Video',
  CREATE_EXERCISE_SUCCESS = 'Exercise created successfully',
  UPDATE_EXERCISE_SUCCESS = 'Successful exercise update',
  UPDATE_EXERCISE_IMAGE_SUCCESS = 'Successful exercise image update',
  UPDATE_EXERCISE_AFFIRMATION_SUCCESS = 'Successful exercise affirmation image update',
  UPDATE_EXERCISE_PREVIEW_VIDEO_SUCCESS = 'Successful exercise video update',
  UPDATE_EXERCISE_PREVIEW_VIDEO_DURATION_SUCCESS = 'Successful exercise video duration update',
  UPDATE_EXERCISE_IMAGE_ERROR = 'Exercise image update error',
  UPDATE_EXERCISE_AFFIRMATION_ERROR = 'Exercise affirmation image update error',
  GET_EXERCISE_ERROR = 'Failed to get exercise',
  CREATE_EXERCISE_ERROR = 'Exercise create error',
  UPDATE_EXERCISE_ERROR = 'Exercise update error',
  UPDATE_EXERCISE_PREVIEW_VIDEO_ERROR = 'Exercise preview video update error',
  UPDATE_EXERCISE_PREVIEW_VIDEO_DURATION_ERROR = 'Exercise preview video duration update error',
  DELETE_EXERCISE_OF_THE_DAY_SUCCESS = 'Exercise successfully deleted',
  DELETE_EXERCISE_OF_THE_DAY_ERROR = 'Failed to delete exercise of the day',
  UPLOAD_VIDEO_TO_S3_ERROR = 'Upload video to AWS S3 error',
}

export enum CoursesSection {
  FOR_DRAFT = 'Open drafts',
  FOR_ACTIVE_COURSES = 'Open active courses',
  CREATE_COURSE = 'Create course',
}

export const pageSize = 10;
export const COURSE_ID_NOT_FOUND = 'Course id not found';
