import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getCourses } from './service';
import {
  getCoursesLoadMoreSelector,
  getCoursesSelector,
  getCoursesLoadingSelector,
} from './selector';
import { CoursesSection, pageSize } from '../../utils/constants';
import CoursesPage from './Page';
import { APP_ROUTES } from '../../core/router/appRoutes';

export interface CoursesProps {
  isDraft: boolean;
}

const Courses: FC<CoursesProps> = ({ isDraft }) => {
  const dispatch = useAppDispatch();
  const coursesResponse = useAppSelector(getCoursesSelector);
  const loadMoreStatus = useAppSelector(getCoursesLoadMoreSelector);
  const isLoading = useAppSelector(getCoursesLoadingSelector);
  const courseSection = isDraft
    ? CoursesSection.FOR_ACTIVE_COURSES
    : CoursesSection.FOR_DRAFT;

  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);

  const updatePage = useCallback((step: number) => {
    setPage(page + step);
  }, []);

  const onDraftClickHandler = useCallback(() => {
    setPage(1);
    if (!isDraft) {
      navigate(`${APP_ROUTES.COURSES}/drafts`);
    } else {
      navigate(`${APP_ROUTES.COURSES}`);
    }
  }, [isDraft]);

  useEffect(() => {
    dispatch(getCourses({ page, pageSize, isDraft }));
  }, [page, isDraft]);

  return (
    <>
      <h1>Courses:</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <CoursesPage
          onDraftClick={onDraftClickHandler}
          courseSection={courseSection}
          courses={coursesResponse.courses}
          loadMoreStatus={loadMoreStatus}
          updatePage={updatePage}
        />
      )}
    </>
  );
};

export default Courses;
