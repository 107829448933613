import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  IGetChallengesResponse,
  IChallengesState,
  IChangePublishStatus,
} from '../types';
import {
  changePublishStatus,
  deleteChallenge,
  getChallenges,
} from '../service';
import { ToastMessage, pageSize } from '../../../utils/constants';
import { IStatusDelete } from '../../Courses/service';

const initialState: IChallengesState = {
  challenges: [],
  count: 0,
  page: 1,
  pageSize,
  loadMore: true,
  isLoading: false,
};

export const challengesSlice = createSlice({
  name: 'challenges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getChallenges.fulfilled,
      (state, { payload }: PayloadAction<IGetChallengesResponse>) => {
        state.challenges =
          state.page > 1
            ? [...state.challenges, ...payload.challenges]
            : payload.challenges;
        state.loadMore = state.challenges.length !== payload.count;
        state.isLoading = false;
      },
    );
    builder.addCase(getChallenges.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getChallenges.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      changePublishStatus.fulfilled,
      (state, { payload }: PayloadAction<IChangePublishStatus>) => {
        state.isLoading = false;
        if (payload.isDraft) {
          toast.success(ToastMessage.CHALLENGE_UNPUBISHED_SUCCESS);
        } else {
          toast.success(ToastMessage.CHALLENGE_PUBISHED_SUCCESS);
        }
      },
    );
    builder.addCase(changePublishStatus.rejected, (state) => {
      state.isLoading = false;
      toast.warning(ToastMessage.CHALLENGE_STATUS_ERROR);
    });

    builder.addCase(changePublishStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteChallenge.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deleteChallenge.fulfilled,
      (state, { payload }: PayloadAction<IStatusDelete>) => {
        toast.success(ToastMessage.DELETE_CHALLENGE_SUCCESS);
        state.isLoading = false;
        state.challenges = state.challenges.filter(
          (challenge) => challenge.id !== payload.id,
        );
      },
    );
    builder.addCase(deleteChallenge.rejected, (state) => {
      toast.warning(ToastMessage.DELETE_CHALLENGE_ERROR);
      state.isLoading = false;
    });
  },
});

export default challengesSlice.reducer;
