import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, del } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import { ITrainer } from '../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export interface IStatusDelete {
  id: string;
}

export const getTrainers = createAsyncThunk<
ITrainer[],
undefined,
ICommonChunkConfig
>('trainers/fetchTrainers', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({ path: API_ROUTES.TRAINERS });
    return response as ITrainer[];
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const deleteTrainer = createAsyncThunk<
IStatusDelete,
IStatusDelete,
ICommonChunkConfig
>('trainers/deleteTrainer', async (params, { rejectWithValue }) => {
  try {
    await del({ path: `${API_ROUTES.TRAINERS}/${params.id}` });
    return { id: params.id } as IStatusDelete;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
