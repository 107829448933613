import React, { memo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import ExerciseForm from '../../Form';
import {
  getExerciseErrorsSelector,
  getLoadingExerciseSelector,
} from '../../selector';
import {
  createExercise,
  updateAffirmationImage,
  updateExerciseVideo,
  updatePreviewImage,
} from '../../service';
import { IExerciseOfTheDayItem } from '../../../ExercisesOfTheDay/types';
import { IExerciseForm } from '../../Form/Form';
import { ToastMessage } from '../../../../utils/constants';

const ExerciseOfTheDayCreatePage = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getLoadingExerciseSelector);
  const errors = useAppSelector(getExerciseErrorsSelector);

  const onSubmit = ({
    title,
    description,
    isDraft,
    showTitle,
    affirmationImageFiles,
    duration,
    position,
    previewImageFiles,
    videoFiles,
  }: IExerciseForm) => {
    const isFormFull =
      description &&
      previewImageFiles &&
      previewImageFiles.length &&
      videoFiles &&
      videoFiles.length &&
      title &&
      duration;
    if (isFormFull) {
      dispatch(
        createExercise({
          description,
          title,
          isDraft,
          showTitle,
          ...(position && { position: position && +position }),
        }),
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          const { id } = payload as IExerciseOfTheDayItem;
          if (id) {
            dispatch(
              updatePreviewImage({
                file: previewImageFiles[0],
                id,
              }),
            );
            dispatch(
              updateExerciseVideo({
                id,
                file: videoFiles[0],
                duration,
              }),
            );

            if (affirmationImageFiles?.length) {
              dispatch(
                updateAffirmationImage({
                  file: affirmationImageFiles[0],
                  id,
                }),
              );
            }
          }
          navigate('/exercises-of-the-day');
        }
      });
    } else {
      toast.warning(ToastMessage.CHECK_ALL_FIELDS);
    }
  };

  useEffect(() => {
    if (errors.length) {
      errors.forEach((e: string) => {
        toast.error(e);
      });
    }
  }, [errors]);

  return (
    <div>
      <h1>Create exercise of the day </h1>
      {isLoading && <CircularProgress />}
      <ExerciseForm type="create" onSubmit={onSubmit} isLoading={isLoading} />
      {isLoading && <p>loading...</p>}
    </div>
  );
});

export default ExerciseOfTheDayCreatePage;
