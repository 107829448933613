import { createAsyncThunk } from '@reduxjs/toolkit';
import { del, get } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import { ICourseShortItem, ICoursesResponse } from '../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export interface IStatusDelete {
  id: string;
}

export interface ICourseRequestParams {
  page: number;
  pageSize: number;
  isDraft: boolean;
}

export const getCourses = createAsyncThunk<
ICoursesResponse,
ICourseRequestParams,
ICommonChunkConfig
>('courses/fetchCourses', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({ path: API_ROUTES.COURSES, params: _data });
    return {
      ...response,
      page: _data.page,
      pageSize: _data.pageSize,
    } as ICoursesResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const getAllCourses = createAsyncThunk<
ICourseShortItem[],
undefined,
ICommonChunkConfig
>('courses/fetchAllCourses', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({ path: `${API_ROUTES.COURSES}/all` });
    return response as ICourseShortItem[];
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const deleteCourse = createAsyncThunk<
IStatusDelete,
IStatusDelete,
ICommonChunkConfig
>('course/deleteCourse', async (params, { rejectWithValue }) => {
  try {
    await del({ path: `${API_ROUTES.COURSES}/${params.id}` });
    return { id: params.id } as IStatusDelete;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
