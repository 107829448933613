import React, { memo } from 'react';
import PageWrapper from '../Wrapper';
import CreateAchievement from './components/CreateAchievement';
import UpdateAchievement from './components/UpdateAchievement';

const AchievementsPage = memo(() => (
  <PageWrapper
    component={UpdateAchievement}
    componentOther={CreateAchievement}
  />
));

export default AchievementsPage;
