import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../../utils/constants';
import {
  createChallenge,
  deleteChallengeDay,
  getAllAchievements,
  getChallenge,
  updateChallenge,
  uploadImage,
} from '../service';
import {
  IAchievement,
  IGetAllAchievements,
  IUploadFileResponse,
} from '../types';
import { ISingleChallenge } from '../../Challenges/types';
import { ISelectOption } from '../../CoursePage/types';
import { DEFAULT_OPTION } from '../../../components/ScrollSelect/contants';
import { IStatusDelete } from '../../Courses/service';

export interface IChallengeState {
  isLoading: boolean;
  challenge: ISingleChallenge | null;
  achievementsForm: ISelectOption[];
  achievements: IAchievement[];
  loadMoreAchievements: boolean;
  page: number;
  url: string;
}

const initialState: IChallengeState = {
  isLoading: false,
  challenge: null,
  achievementsForm: [],
  achievements: [],
  page: 1,
  loadMoreAchievements: true,
  url: '',
};

export const challengeSlice = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    clearAchievements: (state) => {
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      createChallenge.fulfilled,
      (state, { payload }: PayloadAction<ISingleChallenge>) => {
        toast.success(ToastMessage.CREATE_CHALLENGE_SUCCESS);
        state.isLoading = false;
        state.challenge = payload;
      },
    );
    builder.addCase(createChallenge.rejected, (state) => {
      toast.error(ToastMessage.CREATE_CHALLENGE_ERROR);
      state.isLoading = false;
    });
    builder.addCase(createChallenge.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllAchievements.fulfilled,
      (state, { payload }: PayloadAction<IGetAllAchievements>) => {
        state.achievements =
          state.page > 1
            ? [...state.achievements, ...payload.achievements]
            : payload.achievements;
        state.achievementsForm = state.achievements.map((achievement) => ({
          label: achievement.name,
          value: achievement.id,
        }));
        state.loadMoreAchievements =
          state.achievements.length !== payload.count;
      },
    );
    builder.addCase(getAllAchievements.rejected, () => {
      toast.error(ToastMessage.GET_ACHIEVEMENTS_ERROR);
    });
    builder.addCase(getAllAchievements.pending, (state) => {
      state.achievementsForm = DEFAULT_OPTION;
    });
    builder.addCase(
      uploadImage.fulfilled,
      (state, { payload }: PayloadAction<IUploadFileResponse>) => {
        if (payload.url) {
          state.url = payload.url;
          toast.success(ToastMessage.UPLOAD_CHALLENGE_IMAGE_SUCCESS);
        }
        state.isLoading = false;
      },
    );
    builder.addCase(uploadImage.rejected, (state) => {
      toast.error(ToastMessage.UPLOAD_CHALLENGE_IMAGE_ERROR);
      state.isLoading = false;
    });
    builder.addCase(uploadImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getChallenge.fulfilled,
      (state, { payload }: PayloadAction<ISingleChallenge>) => {
        state.challenge = payload;
        state.url = payload.image && payload.image.url ? payload.image.url : '';
        state.isLoading = false;
      },
    );
    builder.addCase(getChallenge.rejected, (state) => {
      state.isLoading = false;
      toast.error(ToastMessage.GET_CHALLENGE_ERROR);
    });
    builder.addCase(getChallenge.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateChallenge.fulfilled, (state) => {
      toast.success(ToastMessage.UPDATE_CHALLENGE_SUCCESS);
      state.isLoading = false;
    });
    builder.addCase(updateChallenge.rejected, (state) => {
      toast.error(ToastMessage.UPDATE_CHALLENGE_ERROR);
      state.isLoading = false;
    });
    builder.addCase(updateChallenge.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteChallengeDay.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deleteChallengeDay.fulfilled,
      (state, { payload }: PayloadAction<IStatusDelete>) => {
        toast.success(ToastMessage.DELETE_CHALLENGE_DAY_SUCCESS);
        state.isLoading = false;
        if (state.challenge?.challengeDays) {
          state.challenge.challengeDays = state.challenge.challengeDays.filter(
            (day) => day.id !== payload.id,
          );
        }
      },
    );
    builder.addCase(deleteChallengeDay.rejected, (state) => {
      toast.warning(ToastMessage.DELETE_CHALLENGE_DAY_ERROR);
      state.isLoading = false;
    });
  },
});

export const { setPage, clearAchievements } = challengeSlice.actions;
export default challengeSlice.reducer;
