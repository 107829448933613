import styled from 'styled-components';
import { ListItemIcon, styled as muiStyled } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

export const StyledContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const StyledChevronLeft = muiStyled(ChevronLeft)(() => ({
  width: '20px',
  height: '20px',
}));

export const StyledIconBox = muiStyled(ListItemIcon)(() => ({
  minWidth: 'fit-content',
}));
