import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../../utils/constants';
import { PreviewType } from '../../CoursePage/types';
import { ILesson } from '../../Courses/types';
import {
  createLesson,
  getLesson,
  ILessonMediaUpdateResponse,
  updateLesson,
  updateLessonImage,
  updateLessonPreviewImage,
  updateLessonPreviewVideo,
  updateLessonVideo,
} from '../service';
import { ILessonForRequest } from '../types';

export interface ILessonState {
  lesson: ILesson | null;
  isLoading: boolean;
  isVideoUploading: boolean;
  errors: string[];
  previewImageStatus: boolean;
  previewVideoStatus: boolean;
  videoStatus: boolean;
  imageStatus: boolean;
}

const initialState: ILessonState = {
  lesson: null,
  isLoading: false,
  isVideoUploading: false,
  errors: [],
  previewImageStatus: false,
  previewVideoStatus: false,
  videoStatus: false,
  imageStatus: false,
};

export const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getLesson.fulfilled,
      (state, { payload }: PayloadAction<ILesson>) => {
        state.lesson = payload;
        state.isLoading = false;
        if (state.errors.length) {
          state.errors = [];
        }
      },
    );
    builder.addCase(getLesson.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.GET_LESSON_ERROR);
    });
    builder.addCase(getLesson.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createLesson.fulfilled, (state) => {
      state.isLoading = false;
      toast.success(ToastMessage.CREATE_LESSON_SUCCESS);
      if (state.errors.length) {
        state.errors = [];
      }
    });
    builder.addCase(createLesson.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.CREATE_LESSON_ERROR);
    });
    builder.addCase(createLesson.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateLesson.fulfilled,
      (state, { payload }: PayloadAction<ILessonForRequest>) => {
        if (state.lesson) {
          if (payload.name) {
            state.lesson.name = payload.name;
          }
          if (payload.description) {
            state.lesson.description = payload.description;
          }
          if (payload.position) {
            state.lesson.position = payload.position;
          }
          state.lesson.previewOption = payload.previewOption
            ?.value as PreviewType;
          state.isLoading = false;
          toast.success(ToastMessage.UPDATE_LESSON_SUCCESS);
          if (state.errors.length) {
            state.errors = [];
          }
        }
      },
    );
    builder.addCase(updateLesson.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_LESSON_ERROR);
    });
    builder.addCase(updateLesson.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateLessonPreviewImage.fulfilled,
      (state, { payload }: PayloadAction<ILessonMediaUpdateResponse>) => {
        if (payload.url) {
          state.previewImageStatus = true;
          state.isLoading = false;
          toast.success(ToastMessage.UPDATE_LESSON_PREVIEW_SUCCESS);
          if (state.errors.length) {
            state.errors = [];
          }
        }
      },
    );
    builder.addCase(updateLessonPreviewImage.rejected, (state) => {
      state.previewImageStatus = false;
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_LESSON_PREVIEW_ERROR);
    });
    builder.addCase(updateLessonPreviewImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateLessonPreviewVideo.fulfilled,
      (state, { payload }: PayloadAction<ILessonMediaUpdateResponse>) => {
        if (payload.url) {
          state.previewVideoStatus = true;
          state.isLoading = false;
          toast.success(ToastMessage.UPDATE_LESSON_PREVIEW_VIDEO_SUCCESS);
          if (state.errors.length) {
            state.errors = [];
          }
        }
      },
    );
    builder.addCase(updateLessonPreviewVideo.rejected, (state) => {
      state.previewVideoStatus = false;
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_LESSON_PREVIEW_VIDEO_ERROR);
    });
    builder.addCase(updateLessonPreviewVideo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateLessonImage.fulfilled,
      (state, { payload }: PayloadAction<ILessonMediaUpdateResponse>) => {
        if (payload.url) {
          state.imageStatus = true;
          state.isLoading = false;
          toast.success(ToastMessage.UPDATE_LESSON_IMAGE_SUCCESS);
          if (state.errors.length) {
            state.errors = [];
          }
        }
      },
    );
    builder.addCase(updateLessonImage.rejected, (state) => {
      state.imageStatus = false;
      state.isLoading = false;
      state.errors.push(ToastMessage.UPDATE_LESSON_IMAGE_ERROR);
    });
    builder.addCase(updateLessonImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateLessonVideo.fulfilled,
      (state, { payload }: PayloadAction<ILessonMediaUpdateResponse>) => {
        if (payload.url) {
          state.videoStatus = true;
          state.isVideoUploading = false;
          toast.success(ToastMessage.UPDATE_LESSON_VIDEO_SUCCESS);
          if (state.errors.length) {
            state.errors = [];
          }
        }
      },
    );
    builder.addCase(updateLessonVideo.rejected, (state) => {
      state.videoStatus = false;
      state.isVideoUploading = false;
      state.errors.push(ToastMessage.UPDATE_LESSON_VIDEO_ERROR);
    });
    builder.addCase(updateLessonVideo.pending, (state) => {
      state.isVideoUploading = true;
    });
  },
});

export default lessonSlice.reducer;
