import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ToastMessage, pageSize } from '../../../utils/constants';
import { ISelectOption } from '../../CoursePage/types';
import { ICourseItem, ICoursesResponse } from '../../Courses/types';
import { ITrainer } from '../../Trainers/types';
import {
  createTrainer,
  getAllCourses,
  getTrainer,
  updateTrainer,
} from '../service';
import { DEFAULT_OPTION } from '../../../components/ScrollSelect/contants';

export interface ITrainerState {
  trainer: ITrainer | null;
  isLoading: boolean;
  errors: string[];
  courses: ICourseItem[];
  coursesForm: ISelectOption[];
  count: number;
  page: number;
  pageSize: number;
  loadMore: boolean;
}

const initialState: ITrainerState = {
  trainer: null,
  isLoading: false,
  errors: [],
  courses: [],
  coursesForm: [],
  count: 0,
  page: 1,
  pageSize,
  loadMore: true,
};

export const trainerSlice = createSlice({
  name: 'trainer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTrainer.fulfilled, (state) => {
      toast.success(ToastMessage.CREATE_TRAINER_SUCCESS);
      state.isLoading = false;
    });
    builder.addCase(createTrainer.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.CREATE_TRAINER_ERROR);
    });
    builder.addCase(createTrainer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getTrainer.fulfilled,
      (state, { payload }: PayloadAction<ITrainer>) => {
        state.trainer = payload;
        state.isLoading = false;
      },
    );
    builder.addCase(getTrainer.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.GET_TRAINER_ERROR);
    });
    builder.addCase(getTrainer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateTrainer.fulfilled,
      (state, { payload }: PayloadAction<ITrainer>) => {
        state.trainer = payload;
        state.isLoading = false;
        toast.success(ToastMessage.UPDATE_TRAINER_SUCCESS);
      },
    );
    builder.addCase(updateTrainer.rejected, (state) => {
      state.isLoading = false;
      state.errors.push(ToastMessage.GET_TRAINER_ERROR);
    });
    builder.addCase(updateTrainer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllCourses.fulfilled,
      (state, { payload }: PayloadAction<ICoursesResponse>) => {
        state.courses =
          payload.page > 1
            ? [...state.courses, ...payload.courses]
            : payload.courses;
        state.coursesForm = state.courses.map((course) => ({
          label: course.name,
          value: course.id,
        }));
        state.loadMore = state.courses.length !== payload.count;
      },
    );
    builder.addCase(getAllCourses.rejected, (state) => {
      state.errors.push(ToastMessage.GET_COURSES_ERROR);
    });

    builder.addCase(getAllCourses.pending, (state) => {
      state.coursesForm = DEFAULT_OPTION;
    });
  },
});

export default trainerSlice.reducer;
