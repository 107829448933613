import React, { FC, memo } from 'react';
import PageWrapper from '../Wrapper';
import CourseCreatePage from './Page/create';
import CourseUpdatePage from './Page/update';

const CoursePage: FC = memo(() => (
  <PageWrapper component={CourseUpdatePage} componentOther={CourseCreatePage} />
));

export default CoursePage;
