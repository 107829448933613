import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, put } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import { ICourseRequestParams } from '../../Courses/service';
import { ICoursesResponse } from '../../Courses/types';
import { ITrainer } from '../../Trainers/types';
import { ICreateTrainer } from '../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

interface ITrainerParams {
  id: string;
}

interface ITrainerUpdateParams extends ICreateTrainer {
  id: string;
}

export const createTrainer = createAsyncThunk<
ITrainer,
ICreateTrainer,
ICommonChunkConfig
>('trainer/createTrainer', async (params, { rejectWithValue }) => {
  try {
    const response = await post(
      {
        path: `${API_ROUTES.TRAINERS}`,
        data: { ...params },
      },
      'multipart/form-data',
    );
    return response as ITrainer;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const getTrainer = createAsyncThunk<
ITrainer,
ITrainerParams,
ICommonChunkConfig
>('trainer/fetchTrainer', async (params, { rejectWithValue }) => {
  try {
    const response = await get({ path: `${API_ROUTES.TRAINERS}/${params.id}` });
    return response as ITrainer;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateTrainer = createAsyncThunk<
ITrainer,
ITrainerUpdateParams,
ICommonChunkConfig
>('trainer/updateTrainer', async (params, { rejectWithValue }) => {
  try {
    const response = await put(
      {
        path: `${API_ROUTES.TRAINERS}/${params.id}`,
        data: { ...params },
      },
      'multipart/form-data',
    );
    return response as ITrainer;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const getAllCourses = createAsyncThunk<
ICoursesResponse,
ICourseRequestParams,
ICommonChunkConfig
>('trainer/fetchCourses', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({ path: API_ROUTES.COURSES, params: _data });
    return {
      ...response,
      page: _data.page,
      pageSize: _data.pageSize,
    } as ICoursesResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
