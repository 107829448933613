import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  Typography,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import React, { FC, memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { ILesson } from '../../../Courses/types';
import AlertDialod from '../../../../components/AlertDialog/alert-dialog';
import { useAppDispatch } from '../../../../hooks';
import { deleteLesson } from '../../service';

interface ILessonComponentProps {
  lesson: ILesson;
}

const LessonCard: FC<ILessonComponentProps> = memo(({ lesson }) => {
  const dispatch = useAppDispatch();
  const [id, setId] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const changeStatus = (courseId: string) => {
    setId(courseId);
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const handleSubmit = async () => {
    if (id) {
      await dispatch(deleteLesson({ id }));
      setOpenDeleteDialog(false);
    }
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          maxWidth: 600,
        }}
      >
        <Link to={`${APP_ROUTES.LESSONS}/${lesson.id}`}>
          {lesson.image?.url && (
            <CardActionArea>
              <CardMedia
                component="img"
                height="240"
                image={lesson.image.url}
                alt={lesson.name}
              />
            </CardActionArea>
          )}
        </Link>
        <CardContent style={{ height: '250px', wordWrap: 'break-word' }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            {lesson.name.length > 35
              ? `${lesson.name.slice(0, 35)}...`
              : lesson.name}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            {lesson.description.length > 180
              ? `${lesson.description.slice(0, 180)}...`
              : lesson.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Link to={`${APP_ROUTES.LESSONS}/${lesson.id}`}>
            <Button size="medium">Edit</Button>
          </Link>
          <Button
            size="medium"
            color="error"
            onClick={() => changeStatus(lesson.id)}
          >
            Delete
          </Button>
        </CardActions>
      </Card>
      {openDeleteDialog && (
        <AlertDialod
          question="Delete lesson?"
          description="Are you sure you want to delete the lesson?"
          buttonAgree="Delete"
          buttonDisagree="Cancel"
          openStatus={openDeleteDialog}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      )}
    </>
  );
});

export default LessonCard;
